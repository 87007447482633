import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';
import customAxios from '../../axios';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, Divider, makeStyles, Box, Button, CircularProgress, TextField, TablePagination } from '@material-ui/core';
import { AccountBoxOutlined, Add, Delete, EditOutlined, ExpandMore as ExpandMoreIcon, HighlightOffOutlined, InsertDriveFileOutlined, PlayCircleOutlineOutlined } from '@material-ui/icons';
import { Card, CardHeader, Row, Col, Container } from "reactstrap"
import moment from "moment";
import 'flag-icon-css/css/flag-icons.min.css'; // Import the styles
import Header from '../User/Header';

import filterIcon from "../../assets/logos/filter.png"
import filterHoverIcon from "../../assets/logos/filter-hover.png"
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';

const colors = [
  '#3498db', '#2ecc71', '#e74c3c', '#f39c12', '#1abc9c', // Blues, Greens, Reds, Yellows, Turquoise
  '#9b59b6', '#34495e', '#95a5a6', '#d35400', '#27ae60', // Purples, Grays, Oranges
  '#2980b9', '#8e44ad', '#2c3e50', '#d35400', '#c0392b', // Blues, Purples, Grays, Oranges, Reds
  '#16a085', '#f39c12', '#3498db', '#2ecc71', '#e74c3c', // Turquoise, Yellows, Blues, Greens, Reds
  '#1abc9c', '#9b59b6', '#34495e', '#95a5a6', '#d35400', // Turquoise, Purples, Grays, Grays, Oranges
  '#27ae60', '#2980b9', '#8e44ad', '#2c3e50', '#d35400', // Greens, Blues, Purples, Grays, Oranges
  '#c0392b', '#16a085', '#f39c12', '#3498db', '#2ecc71', // Reds, Turquoise, Yellows, Blues, Greens
  '#e74c3c', '#1abc9c', '#9b59b6', '#34495e', '#95a5a6', // Reds, Turquoise, Purples, Grays, Grays
  '#d35400', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50'  // Oranges, Greens, Blues, Purples, Grays
];

const iconUrls = [
  "https://storage.googleapis.com/website-paperplane/avatar/av1.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av2.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av3.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av4.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av5.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av6.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av7.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av8.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av9.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av10.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av11.png",
  "https://storage.googleapis.com/website-paperplane/avatar/av12.png",
]

const useStyles = makeStyles((theme) => ({
  tableRow: {
    // borderRadius: "10px",
    // marginTop: "15px",
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', // Equal space for each column
    // gap: theme.spacing(2), // Adjust the gap between items as needed
    // padding: "10px",
    // paddingLeft: "20px",
    // border: "1px solid rgba(179, 182, 183, 0.2)"
    background: "#1c1c1c",
    border: "1px solid rgba(179, 182, 183, 0.2)",

  },  
  table: {
    background: "#1c1c1c",
    border: "1px solid rgba(179, 182, 183, 0.2)",
    borderRadius: "10px",
    marginTop: "15px"
  },
  tableHead: {
    background: "#252525"
  }
}));

function MobFileRenderer({  }) {
  const containerRef = useRef([]);

  const classes = useStyles();

  const [eventData, setEventData] = useState(null)

  const organisation = useSelector(state => state?.user?.user?.organisation)

  useEffect(() => {
    setEventLoader(true)
    if(organisation)
    customAxios.get(`auth/rrweb-event-list?org_id=${organisation.orgId}`).then(res => {

      let colorCodeData = {}
      let cIndex = 0

      setEventLoader(false);

      setEventData([...res?.data?.data?.map(v => {
        if(!colorCodeData?.[v?.rrId]){
          if(cIndex > 11){
            cIndex = 0
          }
          colorCodeData[v.rrId] = iconUrls[cIndex];
          cIndex = cIndex + 1;
        }

        return {...v, avatarUrl: colorCodeData[v.rrId]}
      })]);
    })
  }, [organisation])

  // useEffect(() => {
  //   if (containerRef.current && eventData && eventData?.events) {
  //     // Dynamically render the React code in the mob file
  //     console.log(eventData, '98979897check')

  //     let events = JSON.parse(eventData.events);
  //     new rrwebPlayer({
  //       target: containerRef.current, // customizable root element
  //       props: {
  //         events: events?.events,
  //       },
  //     });
  //   }
  // }, [eventData]);

  const [open, setOpen] = React.useState(false);

  const [rrwebDataLoader, setRRwebDataLoader] = useState(null)
  const [eventLoader, setEventLoader] = useState(false)

  const handleToggle = (index) => {
    if(index === open) {
      setOpen(null)
    } else {
      setOpen(index);
      if(!eventData?.[index]?.events?.length) {
        setRRwebDataLoader(index)
        customAxios.get(`brand/event-data?event_id=${eventData?.[index]?._id}`).then(res => {
          if(res?.data?.success && res?.data?.data) {
          let signedUrl = res?.data?.data;
          fetch(signedUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error('Failed to fetch JSON data: ' + response.statusText);
              }
              return response.json();
            })
            .then((data) => {
              let tempEventData = [...eventData];
              tempEventData[index] = {
                ...tempEventData[index],
                events: data
              };
              setRRwebDataLoader(null)
              setEventData([...tempEventData]);
              playRRwebVideo(index, tempEventData);
            })
            .catch((error) => console.error('Error fetching JSON data:', error));

          
          }
        }).catch(err => console.log(err))
      }
    }
  };

  const playRRwebVideo = (tempOpen = open, tempEventData = eventData) => {
    if((tempOpen === 0 || tempOpen) && tempEventData?.[tempOpen]?.events?.length) {
      let targetElement = containerRef.current[tempOpen]
      
      if(targetElement){
        const player = new rrwebPlayer({
          target: targetElement, // customizable root element
          props: {
            events: tempEventData?.[tempOpen]?.events?.length >= 2 ? tempEventData?.[tempOpen]?.events : [{
              "type": 0,
              "data": {},
              "timestamp": 1701254468765
          },
          {
              "type": 1,
              "data": {},
              "timestamp": 1701254469063
          }]
          },
        });
  
              // Assuming there is an audio element in your rrweb player
          try{
  
            const audioElement = targetElement?.querySelector('audio');
    
            if (audioElement) {
              // Mute the audio element by default
              audioElement.muted = true;
            }
          } catch(err) {
            console.error(err);
          }
      }

      let objDiv = document.getElementById(`bottom-${tempOpen}`)
      if (objDiv) {
        objDiv.scrollIntoView({ behavior: 'instant', block: 'nearest' })
      }
    }
  }

  useEffect(() => {
    playRRwebVideo()
  }, [String(open)])

  const [hoverIndex, setHoverIndex] = React.useState(null)

  const getFilteredEvents = () => {
    if(eventData?.length){
      const filteredEvents = eventData?.filter((event) => {
        let userAgentData = parseUserAgent(event?.userData?.ipData?.userAgent)
        let cityString = `${event?.userData?.dlData?.ip_data?.city}`
        let regionString = event?.userData?.dlData?.ip_data?.region
        let countryCode = event?.userData?.dlData?.ip_data?.country;

        let filterObject = {
          "updatedAt": moment.utc(event?.date),
          "country": countryCode,
          "region": regionString,
          "city": cityString,
          "browser": userAgentData?.browser,
          "os": userAgentData?.os,
          "device": userAgentData?.device
        }

        return filters?.length ? filters.every((filter) => {
          if(!filter?.key || !filter?.value?.length) {
            return true
          }
          else if(filterObject?.[filter?.key]?.toLowerCase()?.includes(filter?.value?.toLowerCase())){
            return true
          } else {
            return false
          }
        }) : true
      })

      return [...filteredEvents]
    } else {
      return []
    }
  }


  const getSessions = () => {

    const filteredEventData = getFilteredEvents()

    if (filteredEventData?.length) {

      return (
        filteredEventData
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((event, index) => {

          const isOdd = index % 2 !== 0;
  
          const userAgentData = parseUserAgent(event?.userData?.ipData?.userAgent)
          const cityString = `${event?.userData?.dlData?.ip_data?.city}`
          const regionString = event?.userData?.dlData?.ip_data?.region
          //Event${event?.addOnEvents?.length ? 's' : ''}
          const eventNumbers = `${event?.addOnEvents?.length ? event?.addOnEvents?.length : "0"}`

          const updateAt = calculateUpdatedAt(moment.utc(event?.date))

          const countryCode = event?.userData?.dlData?.ip_data?.country;
          let tempIdx = index + (page*rowsPerPage)
  
          return (
            <React.Fragment>
              <TableRow container alignItems='center' style={{background: hoverIndex === tempIdx ? "#252525" : "#1c1c1c", cursor: "pointer"}} className={classes.tableRow}
                onMouseEnter={() => setHoverIndex(tempIdx)}
                onMouseLeave={() => setHoverIndex(null)}
                onClick={() => {
                  handleToggle(tempIdx);
                }}
              >
                
                <TableCell item style={{color: "#AAB7B8"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {/* <div style={{width: 30, height: 10, borderRadius: 50, backgroundColor: event?.colorCode, marginRight: 10}}></div> */}
                        <img src={event?.avatarUrl} style={{width: "30px", height: "30px", marginRight: "10px"}}/>
                        Anonymous User
                    </div>
                </TableCell>
                {/* <TableCell item style={{color: "#AAB7B8"}}>
                  <Typography>
                    <span>{userAgentData?.browser}</span>
                    <span style={{margin: 5, fontSize: "18px"}}>.</span>
                    <span>{userAgentData?.os}</span>
                    <span style={{margin: 5, fontSize: "18px"}}>.</span>
                    <span>{userAgentData?.device}</span>
                  </Typography>
                    {/* {moment.utc((event?.date)).format('MMM DD, hh:mm A')}
                    <div style={{opacity: "0.5", fontSize: "12px", marginTop: "5px"}}>
                        {eventNumbers}
                    </div> 
                </TableCell>
                 */}

                <TableCell item style={{color: "#AAB7B8"}}>
                  <Typography>
                    {eventNumbers}
                  </Typography>
                </TableCell>

                <TableCell item style={{color: "#AAB7B8"}}>
                  <Typography>
                    {updateAt}
                  </Typography>
                </TableCell>

                <TableCell item style={{color: "#AAB7B8", display: "flex", alignItems: "center"}}>
                  <div className={`flag-icon flag-icon-${countryCode.toLowerCase()}`} style={{marginRight: "10px"}}></div>
                  <Typography>
                    {countryCode}
                  </Typography>
                </TableCell>

                <TableCell item style={{color: "#AAB7B8"}}>
                  <Typography>
                    {regionString}
                  </Typography>
                </TableCell>

                <TableCell item style={{color: "#AAB7B8"}}>
                  <Typography>
                    {cityString}
                  </Typography>
                </TableCell>

                <TableCell item style={{color: "#AAB7B8"}}>
                  <Typography>
                    {userAgentData?.browser}
                  </Typography>
                </TableCell>

                <TableCell item style={{color: "#AAB7B8"}}>
                  <Typography>
                    {userAgentData?.os}
                  </Typography>
                </TableCell>

                <TableCell item style={{color: "#AAB7B8"}}>
                  <Typography>
                    {userAgentData?.device}
                  </Typography>
                </TableCell>

                <TableCell item style={{color: "#AAB7B8"}}>
                  <IconButton size="small" onClick={() => handleToggle(tempIdx)} style={{color: "#00FF38"}}>
                    {open === tempIdx ? <HighlightOffOutlined /> : <PlayCircleOutlineOutlined />}
                  </IconButton>
                </TableCell>

              </TableRow>
              {/* <TableRow className={classes.tableRow}>
              </TableRow> */}
              {open === tempIdx ? (
                <TableRow>
                  <TableCell colSpan={9} style={{paddingLeft: "16%", paddingTop: "1%"}}>
                      <Collapse in={open===tempIdx} timeout="auto" unmountOnExit>
                        <div style={{backgroundColor: "#34495E"}} ref={ref => containerRef.current[tempIdx] = ref}></div> 
                        {rrwebDataLoader === tempIdx ? <div style={{textAlign: "center"}}>
                          <CircularProgress style={{color: "white", marginRight: "10%"}}/>
                        </div> : null }
                      </Collapse>
                      <div id={`bottom-${tempIdx}`}></div>
                    </TableCell>
                </TableRow>
              ) : null}
            </React.Fragment>
          )
        })
      )
    } else {
      return (
        <TableRow container justifyContent='center' className={classes.tableRow}>
          <TableCell colSpan={11}>
            <div style={{textAlign: "center"}}>
              {
              !eventLoader ? 
                <Typography>
                  No Session Available
                </Typography> :
                <CircularProgress style={{color: "white", marginRight: "10%"}}/>
              }
            </div> 
          </TableCell>
        </TableRow>
      )
    }
  }
  

  const [hoverButton, setHoverButton] = useState(null)
  const [filters, setFilters] = useState([]);

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleAddFilter = () => {
    setFilters([...filters, { key: '', value: '' }]);
  };

  return (
    <>
    {/* <Header parentName="Replay" /> */}
    <div className="header pb-6">
    <Container className="mt--6" fluid>
      <Row>
        <Col className="mt-2">
          {/* <Card > */}
            {/* <CardHeader className="border-0" style={{backgroundColor: "#161b22"}}> */}
            {/* <CardHeader className="border-0" style={{backgroundColor: "#1c1c1c"}}> */}
              <Row style={{ alignItems: "center", marginTop: "8%" }}>
                <Col md="10">
                  <h2 className="mb-0" style={{color: "white"}}>Session Replays</h2>
                </Col>{" "}
              </Row>

              <Row style={{ alignItems: "center", marginTop: "1%", marginBottom: "1.5%"}}>
                <Col md="12">
                  <Grid container alignItems='center'>
                    {/* <Grid item container alignItems='center' xs={4} spacing={1}>
                      <Grid item>
                        <Button variant="text" startIcon={
                            <Add style={{color: hoverButton === "filter" ? "#7856FF" : "white"}} />
                            }
                            onMouseEnter={() => setHoverButton("filter")}
                            onMouseLeave={() => setHoverButton(null)}
                            style={{
                              backgroundColor: hoverButton === "filter" ? "#252525" : null,
                            }}
                          >
                            <Typography style={{
                              color: hoverButton === "filter" ? "#7856FF" : "white",
                            }} 
                            // onClick={handleAddFilter}
                            >
                              Filter
                            </Typography>
                          </Button>
                      </Grid>
                    </Grid> */}

                    {/* <Grid item container xs={8} alignItems='center' justifyContent='end' style={{justifyContent: "end"}} spacing={2}>
                      

                      <Grid item>
                        <Button variant="text" startIcon={
                              <EditOutlined style={{ color: hoverButton === "edit" ? "#7856FF" : "white" }} />
                          }
                            onMouseEnter={() => setHoverButton("edit")}
                            onMouseLeave={() => setHoverButton(null)}
                            style={{
                              backgroundColor: hoverButton === "edit" ? "#252525" : null,
                            }}
                          >
                            <Typography style={{
                              color: hoverButton === "edit" ? "#7856FF" : "white",
                            }} >
                              Edit Columns
                            </Typography>
                          </Button>
                      </Grid>

                      <Grid item>
                        <Button variant="text" startIcon={
                              <InsertDriveFileOutlined style={{ color: hoverButton === "export" ? "#7856FF" : "white" }} />
                          }
                            onMouseEnter={() => setHoverButton("export")}
                            onMouseLeave={() => setHoverButton(null)}
                            style={{
                              backgroundColor: hoverButton === "export" ? "#252525" : null,
                            }}
                          >
                            <Typography style={{
                              color: hoverButton === "export" ? "#7856FF" : "white",
                            }} >
                              Export
                            </Typography>
                          </Button>
                      </Grid>

                      <Grid item>
                        <Button variant="text" startIcon={
                              <AccountBoxOutlined style={{ color: hoverButton === "add" ? "#7856FF" : "white" }} />
                          }
                            onMouseEnter={() => setHoverButton("add")}
                            onMouseLeave={() => setHoverButton(null)}
                            style={{
                              backgroundColor: hoverButton === "add" ? "#252525" : null,
                            }}
                          >
                            <Typography style={{
                              color: hoverButton === "add" ? "#7856FF" : "white",
                            }} >
                              Add/Edit Profile
                            </Typography>
                          </Button>
                      </Grid>
                    </Grid> */}
                    <Grid item container xs={12}>
                      <TableFilter filters={filters} setFilters={setFilters} t />
                    </Grid>
                    
                  </Grid>
                </Col>{" "}
              </Row>
            {/* </CardHeader> */}
              {/* <Divider /> */}

              <TableContainer className={classes.table}>
                <Table>
                  <TableHead className={classes.tableHead}>
                    <TableCell>
                      <Typography>
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        No. of Events
                      </Typography>
                      </TableCell>
                    <TableCell>
                      <Typography>
                        Updated At
                      </Typography>
                      </TableCell>
                    <TableCell>
                      <Typography>
                        Country
                      </Typography>
                      </TableCell>
                    <TableCell>
                      <Typography>
                        Region
                      </Typography>
                      </TableCell>
                    <TableCell>
                      <Typography>
                        City
                      </Typography>
                      </TableCell>
                      <TableCell>
                      <Typography>
                        Browser
                      </Typography>
                      </TableCell>
                    <TableCell>
                      <Typography>
                        OS
                      </Typography>
                      </TableCell>
                    <TableCell>
                      <Typography>
                        Device
                      </Typography>
                      </TableCell>
                    <TableCell>
                      
                      <Typography>
                        Play/Pause
                      </Typography>
                      </TableCell>
                  </TableHead>
                  <TableBody>
                    {getSessions()}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={{color: "white"}}
                align="center"
                backIconButtonProps={{style: {color: "white"}}}
                nextIconButtonProps={{style: {color: "white"}}}
                SelectProps={{style: {color: "white"}}}
                rowsPerPageOptions={[
                  5,
                  10,
                  20,
                  50,
                  { label: "All", value: eventData?.length },
                ]}
                component="div"
                count={eventData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => {
                  setPage(page)
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(e.target.value)
                  setPage(0)
                }}
              />

              
          {/* </Card> */}
        </Col>
    </Row>
    </Container>
    </div>
    </>
  );
}

export default MobFileRenderer;


function parseUserAgent(userAgent) {
  const result = {
      os: "Other",
      browser: "Other",
      device: "Other"
  };

  // Operating System
  if (/Windows/.test(userAgent)) {
      result.os = 'Windows';
  } else if (/Mac OS/.test(userAgent)) {
      result.os = 'Mac OS';
  } else if (/Android/.test(userAgent)) {
      result.os = 'Android';
  } else if (/iOS/.test(userAgent)) {
      result.os = 'iOS';
  } else if (/Linux/.test(userAgent)) {
      result.os = 'Linux';
  }

  // Browser
  if (/Firefox/.test(userAgent)) {
      result.browser = 'Firefox';
  } else if (/Chrome/.test(userAgent)) {
      result.browser = 'Chrome';
  } else if (/Safari/.test(userAgent)) {
      result.browser = 'Safari';
  } else if (/Edge/.test(userAgent)) {
      result.browser = 'Edge';
  } else if (/MSIE/.test(userAgent) || /Trident/.test(userAgent)) {
      result.browser = 'Internet Explorer';
  }

  // Device (Desktop/Mobile)
  if (/Mobile/.test(userAgent)) {
      result.device = 'Mobile';
  } else {
      result.device = 'Desktop';
  }

  return result;
}


function calculateUpdatedAt(previousDate) {
  const currentDate = new Date();
  const timeDifference = currentDate - previousDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (seconds > 0) {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  } else {
    return 'Just now';
  }
}

const TableFilter = ({ tableData, onFilter, filters, setFilters }) => {

  const handleDeleteFilter = (index) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const handleFilterChange = (index, field, value) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    setFilters(newFilters);
  };

  return (
    <div>
      {filters.map((filter, index) => (
        <Grid container spacing={2} alignItems="center" key={index}>
          <Grid item xs={4}>
            <Autocomplete
              options={["updatedAt", "country", "region", "city", "browser", "os", "device"]}
              value={filter.key}
              onChange={(event, newValue) =>
                handleFilterChange(index, 'key', newValue)
              }
              renderInput={(params) => (
                <TextField {...params} label="Key" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            {/* <Autocomplete
              options={Object.values(tableData[index])}
              value={filter.value}
              freeSolo={true}
              
              onChange={(event, newValue) =>
                handleFilterChange(index, 'value', newValue)
              }
              renderInput={(params) => (
                <TextField {...params} label="Value" variant="outlined" />
              )}
            /> */}
            <TextField variant='outlined' fullWidth />
          </Grid>
          {/* <Grid item xs={2}>
            {index === filters.length - 1 && (
              <IconButton onClick={handleAddFilter}>
                <AddIcon />
              </IconButton>
            )}
          </Grid> */}
          <Grid item xs={2}>
            <IconButton onClick={() => handleDeleteFilter(index)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      {/* <button onClick={applyFilter}>Apply Filter</button> */}
    </div>
  );
};