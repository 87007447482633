
import React, { useEffect, useState } from "react"

// import Header from "../../../components/Dashboard/Home/Header"
// import UtilizedCapacity from "../../../components/Dashboard/Analytics/UtlizedCapacity";

import moment from "moment"
import customAxios from "../../../axios"
import { useSelector } from "react-redux"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"

import {
  Container,
  Row,
  Col,
} from "reactstrap"

import Lottie from 'react-lottie';

import { toast } from "react-toastify"
import DateRangePicker from "../../../components/Dashboard/Home/DateRangePicker"
import LoadingAnimation from "../examples/LoadingAnimation"
import DateTypeToggle from "../../../components/Common/DateTypeToggle"
import { Autocomplete } from "@material-ui/lab"

import {
  curveCatmullRom,
  area,
} from 'd3-shape';
import { RangeDatePicker } from "react-google-flight-datepicker"

import { styled } from "@material-ui/styles"
import Header from "../../../components/User/Header"

import { Grid, Typography, Divider, makeStyles, Button, IconButton, Menu, MenuItem, withStyles, Tooltip, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, TablePagination, Tabs, Tab, Box } from "@material-ui/core"

import animationData from '../../../assets/animationData/97071-infinite-scroll-loader.json'

import ReactDatetime from "react-datetime";

import filterIcon from "../../../assets/logos/filter.png"
import linkIcon from "../../../assets/logos/link.png"

import filterHoverIcon from "../../../assets/logos/filter-hover.png"
import linkHoverIcon from "../../../assets/logos/link-hover.png"

import { BarChart, Bar, XAxis, YAxis, Legend, CartesianGrid, LabelList, ResponsiveContainer, Label, Rectangle, PieChart, Pie, Cell } from 'recharts';
import CustomizeBarChart from "./customizeBarChart"
import { AccountCircleOutlined, AddToPhotosOutlined, Close, Delete, DeleteOutlineOutlined, EditOutlined, FavoriteBorderOutlined, Filter, FilterList, Launch, MoreHorizRounded, NearMeOutlined, RefreshOutlined, SettingsOutlined, TvOutlined } from "@material-ui/icons"
import AnalyticMetricCard from "./analyticMetricCard"
import { DataGrid } from "@mui/x-data-grid"
import PropTypes from "prop-types"
import { TableFilter } from "../../../components/TableFilter"


const mostViewedProductData = [
  { productLink: "https://weezy.in/products/shiver-down-my-spine", xValue: 'Product 1', yValue: 92, unitValue: "views", image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391" },
  { unitValue: "views", image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391", productLink: "https://weezy.in/products/shiver-down-my-spine", xValue: 'Product 2', yValue: 83 },
  { unitValue: "views", image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391", productLink: "https://weezy.in/products/shiver-down-my-spine", xValue: 'Product 3', yValue: 38 },
  { unitValue: "views", image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391", productLink: "https://weezy.in/products/shiver-down-my-spine", xValue: 'Product 4', yValue: 13 },
  { unitValue: "views", image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391", productLink: "https://weezy.in/products/shiver-down-my-spine", xValue: 'Product 5', yValue: 10 },
  // Add more data points as needed
];

const mostBoughtProductData = [
  { 
    productLink: "https://weezy.in/products/shiver-down-my-spine", 
    xValue: 'Product 1', 
    yValue: 92, 
    image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391"
   },
  { 
    image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391", 
    productLink: "https://weezy.in/products/shiver-down-my-spine",
     xValue: 'Product 2', 
     yValue: 83
     },
  { image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391", productLink: "https://weezy.in/products/shiver-down-my-spine", xValue: 'Product 3', yValue: 38 },
  { image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391", productLink: "https://weezy.in/products/shiver-down-my-spine", xValue: 'Product 4', yValue: 13 },
  { image: "https://cdn.shopify.com//s//files//1//0604//4529//4788//files//shiverdownmyspine-0077.jpg?v=1706337391", productLink: "https://weezy.in/products/shiver-down-my-spine", xValue: 'Product 5', yValue: 10 },
  // Add more data points as needed
];
export const colors = [
  '#7856FF', '#FF7557', '#80E1D9', '#F8BC3B', '#B2596E',
  '#72BEF4', '#FFB27A', '#0D7EA0', '#3BA974', '#FEBBB2',
  '#CA80DC', '#5BB7AF', '#7856FF', '#FF7557', '#80E1D9',
  '#F8BC3B', '#B2596E', '#72BEF4', '#FFB27A', '#0D7EA0',
  '#3BA974', '#FEBBB2', '#CA80DC', '#5BB7AF', '#7856FF',
  '#FF7557', '#80E1D9', '#F8BC3B', '#B2596E', '#72BEF4',
  '#FFB27A', '#0D7EA0', '#3BA974', '#FEBBB2', '#CA80DC',
  '#5BB7AF', '#7856FF', '#FF7557', '#80E1D9', '#F8BC3B',
  '#B2596E', '#72BEF4', '#FFB27A', '#0D7EA0', '#3BA974'
]

const useStyles = makeStyles((theme) => ({
  tableRow: {
    borderRadius: "10px",
    marginTop: "10px",
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr', // Equal space for each column
    gap: theme.spacing(2), // Adjust the gap between items as needed
    padding: "10px",
    background: "black",
    paddingLeft: "20px"
  },

  cardBody: {
    borderRadius: "20px",
    marginTop: "20px",
    background: "#1c1c1c"
  }

}));

const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: "10px",
    '&:hover': {
      backgroundColor: "#252525", // Set the background color on hover
      color: theme.palette.primary.main
    },
  },
}))(MenuItem);

const CustomToolTip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "whitesmoke", // Set the background color of the tooltip
    color: "black", // Set the text color of the tooltip
    fontSize: "12px", // Set the font size if needed
    border: "whitesmoke"
  },
  arrow: {
    color: "whitesmoke", // Set the color of the arrow
    border: "whitesmoke"
  },
}))(Tooltip);

const Analytics = () => {

  const [hoverButton, setHoverButton] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const [graphData, setGraphData] = useState({
    barGraph1: [],
    barGraph2: [],
    lineGraph1: [],
    uniqueSessions: 0,
    barGraph2Count: 0,
    deviceData: [],
    totalCitySessions: 0
  })

  const [dateFilter, setDateFilter] = useState("last_30")
  const [analyticsLoader, setAnalyticsLoader] = useState(false)

  const updateGraphData = (creator = creatorFilter, tempVal = value) => {
    setAnalyticsLoader(true)

    if(tempVal === 0) {

      customAxios.get(creator ? `/analytics/sales-analytics/${dateFilter}?creator_id=${creator['instaHandle']}` : `/analytics/sales-analytics/${dateFilter}`).then(res => {
        if(res?.data?.success) {
          let responseData = res?.data?.data;
          let tempBarGraph1 = responseData?.userSessionLength?.map(v => ({...v, yValue: v?.yValue?.toFixed(2)}));
  
          let tempBarGraph2Count = 0
          let tempBarGraph2 = responseData?.citySessionData?.map(v => {
            tempBarGraph2Count = v?.count > tempBarGraph2Count ? v?.count : tempBarGraph2Count
            return {
              xValue: v?.city,
              yValue: v?.count,
              unitValue: "users",
              overallPercent: v?.overallPercentage?.toFixed(2),
            }
          });
  
          let tempLineGraph1 = responseData?.sessionsPerDay?.map(v => (
            { name: v?.name, unit: "sessions", 'No. of Sessions': v?.count, percentageChange: v?.percentage?.toFixed(2), isIncrease: (v?.percentage > 0 ? true : false) }
          ));
  
          let tempDeviceData = responseData?.deviceData?.map(v => ({label: v?.device, value: v?.count}))
          
          setOrderMetrics(responseData?.order_metric);
          setOrderRows(responseData?.order_data)
  
          let tempMostViewedCount = 0
          let tempMostViewed = responseData?.mostViewed?.map(v => {
              tempMostViewedCount = v?.count > tempMostViewedCount ? v?.count : tempMostViewedCount;
              return { unitValue: "views", image: v?.image, productLink: v?.productLink, xValue: v?.name, yValue: v?.count }
          })
  
          let tempMostBoughtCount = 0
          let tempMostBought = responseData?.mostBought?.map(v => {
              tempMostBoughtCount = v?.count > tempMostBoughtCount ? v?.count : tempMostBoughtCount;
              return { unitValue: "items", image: v?.image, productLink: v?.productLink, xValue: v?.name, yValue: v?.count }
          })
  
          setProductData({
            mostViewed: tempMostViewed,
            mostBought: tempMostBought,
            mostBoughtCount: tempMostBoughtCount,
            mostViewedCount: tempMostViewedCount
          })
  
          setGraphData({
            barGraph1: tempBarGraph1,
            barGraph2: tempBarGraph2,
            lineGraph1: tempLineGraph1,
            deviceData: tempDeviceData,
            uniqueSessions: responseData?.uniqueSessions,
            totalCitySessions: responseData?.totalCitySessions,
            barGraph2Count: tempBarGraph2Count
          })
  
          setAnalyticsLoader(false)
        }
      });
    }

    else if(tempVal === 1){
      customAxios.get(creator ? `/analytics/campaign-analytics?creator_id=${creator['_id']}`: `/analytics/campaign-analytics`).then(res => {
        if(res?.data?.success) {
          let responseData = res?.data?.data;
          
          setContentReachNumber(responseData?.totalReach)
          setContentRows(res?.data?.data?.campaignData)
          setAnalyticsLoader(false)
        }
      });
    }
  }

  const handleClose = (update = null) => {
    setAnchorEl(null);
    setAnchorEl1(null)

    // if(update){
    //   updateGraphData()
    // }
  };

  useEffect(() => {
    updateGraphData()
  }, [dateFilter])

  const orderColumns = [
    { field: 'id',
    headerName: 'Order ID' },
    {
      field: 'date',
      headerName: 'Date',
    },
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      field: 'phoneNo',
      headerName: 'Phone No',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'lineItems',
      headerName: 'Line Items',
    },
    {
      field: 'price',
      headerName: 'Total Price',
    },
    {
      field: 'discoveredBy',
      headerName: 'Discovery From',
    },
    {
      field: 'discountCode',
      headerName: 'Discount Coupon',
    },
  ];

  const contentColumn = [
    { field: 'creator',
    headerName: 'Creator' },
    {
      field: 'reel',
      headerName: 'Reel',
    },
    {
      field: 'likes',
      headerName: 'Likes',
    },
    {
      field: 'plays',
      headerName: 'No. of Plays',
    },
    {
      field: 'reach',
      headerName: 'Reach',
    },
    {
      field: 'saved',
      headerName: 'No, of Save',
    },
    {
      field: 'share',
      headerName: 'No. of Share',
    },
    {
      field: 'comments',
      headerName: 'Comments',
    }
  ];
  
  const [orderRows, setOrderRows] = useState([])
  const [orderFilterRows, setOrderFilterRows] = useState([])
  const [orderInfoFilter, setOrderInfoFilter] = useState(null)

  useEffect(() => {
    const tempRows = orderRows ? [...orderRows] : [];
    setOrderFilterRows([...tempRows])
  }, [orderRows])

  const [contentRows, setContentRows] = useState([])
  const [productData, setProductData] = useState({})
  const [orderMetrics, setOrderMetrics] = useState(null)
  const [creatorFilter, setCreatorFilter] = useState(null)
  const [contentReachNumber, setContentReachNumber] = useState(0)

  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
    setCreatorFilter(null)
    updateGraphData(null, newValue)
  }

  const [creatorList, setCreatorList] = useState([])
  useEffect(() => {
    customAxios.get('/creator/creator-list').then(res => {
      if(res?.data?.success){
        setCreatorList([...res?.data?.data])
      }
    })
  }, [])

  const filterOrderInfoBySource = (sourceName) => {
    setOrderInfoFilter(sourceName);

    if(orderRows?.length) {
      let tempRows = [...orderRows]?.filter(f => f?.discoveredBy === sourceName)
      setOrderFilterRows([...tempRows])
    }
  }

  const getOrderInfoFilter = () => {
    return (
      <React.Fragment>
         {!orderInfoFilter ? <Button variant="text" style={{marginRight: "10px"}} startIcon={<FilterList style={{color: "white"}} />}
              onClick={(e) => {
                handleClick(e)
              }}
            >
              <Typography style={{
                color: "white",
              }} >
                Filter by Source
              </Typography>
            </Button> :  <Button variant="text" style={{marginRight: "10px"}} startIcon={
                <Close style={{color: "white"}}/>
            }
              onClick={() => {
                setOrderInfoFilter(null)
                const tempRows = orderRows ? [...orderRows] : [];
                setOrderFilterRows([...tempRows])
              }}
            >
              <Typography style={{
                color:"white"
              }} >
                Remove Filter "{orderInfoFilter}"
              </Typography>
            </Button>}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{top: 50, left: -30, 
              }}
              PaperProps={{ style: {
                background: "#1c1c1c",
                border: "1px solid rgba(179, 182, 183, 0.2)",
                color: "white",
                width: "150px"
              }}}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledMenuItem onClick={() => {
                  handleClose()
                  filterOrderInfoBySource("CreatorPay")
              }}>
                  CreatorPay
              </StyledMenuItem>
              <StyledMenuItem onClick={() => {
                  handleClose()
                  filterOrderInfoBySource("Google")
              }}>
                  Google
              </StyledMenuItem>
              <StyledMenuItem onClick={() => {
                  handleClose()
                  filterOrderInfoBySource("Facebook")
              }}>
                  Facebook
              </StyledMenuItem>
              <StyledMenuItem onClick={() => {
                  handleClose()
                  filterOrderInfoBySource("Other")
              }}>
                  Other
              </StyledMenuItem>
            </Menu>
      </React.Fragment>
    )
  }

  return (
    <>
      <div className="header pb-6">
        <Container fluid>
            <Row style={{marginTop: "3%"}}>
              <Col md="12">
                    <Row>
                      <Col md="12">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // padding: "10px",
                            paddingLeft: "0px"
                          }}
                        >
                          <div>
                            <h2 className="mb-0" style={{color: "white"}}>Analytics</h2>
                          </div>
                          <div>
                          {/* <RangeDatePicker
                            startDate={new Date(broadcastDate?.start)}
                            endDate={new Date(broadcastDate?.end)}
                            onChange={(startDate, endDate) => {
                              if(startDate && endDate) {
                                setBroadcastDate({...broadcastDate, "start": moment.utc(startDate).toISOString(), "end": moment.utc(endDate).toISOString()})
                              }
                            }}
                            // fullWidth
                            startDatePlaceholder={"From"}
                            endDatePlaceholder={"To"}
                            // singleCalendar
                          />{" "}
                          */}
                          {/* <DateTypeToggle toggle={dateToggle} onChange={(val) => setDateToggle(val)} /> */}
                          <div>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                  {!creatorFilter ? <Button variant="text" startIcon={
                                      <img src={hoverButton === "filter" ? filterHoverIcon : filterIcon} style={{height: "15px", width: "15px"}}/>
                                  }
                                    onMouseEnter={(e) => {
                                      if(!creatorFilter){
                                        setHoverButton("filter")
                                        handleClick1(e)
                                      }
                                    }}
                                    onMouseLeave={() => {
                                      if(!creatorFilter){
                                        setHoverButton(null)
                                      }
                                    }}
                                    style={{
                                      backgroundColor: hoverButton === "filter" ? "#252525" : null,
                                    }}
                                  >
                                    <Typography style={{
                                      color: hoverButton === "filter" ? "#7856FF" : "white",
                                    }} >
                                      Select Creator
                                    </Typography>
                                  </Button> :  <Button variant="text" startIcon={
                                      <Close style={{color: "#7856FF"}}/>
                                  }
                                    onClick={() => {
                                      updateGraphData(null)
                                      setCreatorFilter(null)
                                    }}
                                  >
                                    <Typography style={{
                                      color:"#7856FF"
                                    }} >
                                      Remove Filter
                                    </Typography>
                                  </Button>}
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl1}
                                    open={Boolean(anchorEl1)}
                                    onClose={handleClose}
                                    onMouseEnter={(e) => {
                                      setHoverButton("filter")
                                    }}
                                    style={{top: 50, left: -30, 
                                    }}
                                    PaperProps={{ style: {
                                      background: "#1c1c1c",
                                      border: "1px solid rgba(179, 182, 183, 0.2)",
                                      color: "white",
                                      width: "150px"
                                    }}}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    onMouseLeave={() => {
                                      setHoverButton(null) 
                                      handleClose()
                                    }}
                                  >
                                    {creatorList?.map(creator => (
                                      <StyledMenuItem onClick={() => {
                                          handleClose(true)
                                          updateGraphData(creator)
                                          setCreatorFilter(creator)
                                      }}>
                                        <AccountCircleOutlined fontSize="12px" style={{marginRight: "10px", height: "18px", width: "18px"}} />
                                        {creator['username']}
                                      </StyledMenuItem>
                                    ))}
                                  </Menu>
                              </Grid> 
                              
                              {/* <Grid item style={{marginRight: "5px"}}>
                              <CustomToolTip arrow title="Manage Sharing">
                                <Button variant="text"
                                  onMouseEnter={() => setHoverButton("share")}
                                  onMouseLeave={() => setHoverButton(null)}
                                  style={{
                                    backgroundColor: hoverButton === "share" ? "#252525" : null,
                                  }}
                                >
                                  <Typography style={{
                                    color: hoverButton === "share" ? "#7856FF" : "white",
                                  }}>
                                    Share
                                  </Typography>
                                </Button>
                                </CustomToolTip>
                              </Grid>

                              <Grid item style={{marginRight: "15px"}}>
                              <CustomToolTip arrow title="Copy Url">
                                <IconButton
                                  onMouseEnter={() => setHoverButton("link")}
                                  onMouseLeave={() => setHoverButton(null)}
                                  style={{ height: "15px", width: "15px",
                                    backgroundColor: hoverButton === "link" ? "#252525" : null,
                                  }}>
                                <img src={hoverButton === "link" ? linkHoverIcon : linkIcon} style={{height: "15px", width: "15px"}}/>
                                </IconButton>
                                </CustomToolTip>
                              </Grid>

                              <Grid item style={{marginRight: "15px"}}>
                              <CustomToolTip arrow title="Favorite">
                                <IconButton
                                  onMouseEnter={() => setHoverButton("heart")}
                                  onMouseLeave={() => setHoverButton(null)}
                                  style={{ height: "15px", width: "15px", 
                                    backgroundColor: hoverButton === "heart" ? "#252525" : null,
                                    color: hoverButton === "heart" ? "#7856FF" : "white",
                                  }}>
                                  <FavoriteBorderOutlined style={{height: "20px", width: "20px"}} />
                                </IconButton>
                                </CustomToolTip>
                              </Grid>

                              <Grid item style={{marginRight: "10px"}}>
                                <IconButton
                                  onMouseEnter={(e) => {
                                    setHoverButton("more")
                                    handleClick(e)
                                  }}
                                  onMouseLeave={() => {
                                    setHoverButton(null)
                                  }}
                                  style={{ 
                                    height: "15px", 
                                    width: "15px", 
                                    backgroundColor: hoverButton === "more" ? "#252525" : null,
                                    color: hoverButton === "more" ? "#7856FF" : "white",
                                  }}>
                                  <MoreHorizRounded />
                                </IconButton>

                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  onMouseEnter={(e) => {
                                    setHoverButton("more")
                                  }}
                                  style={{top: 45, left: -30, 
                                  }}
                                  PaperProps={{ style: {
                                    background: "#1c1c1c",
                                    border: "1px solid rgba(179, 182, 183, 0.2)",
                                    color: "white",
                                    width: "150px"
                                  }}}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  onMouseLeave={() => {
                                    setHoverButton(null)
                                    handleClose()
                                  }}
                                >
                                  <StyledMenuItem onClick={handleClose}>
                                    <DeleteOutlineOutlined fontSize="12px" style={{
                                      marginRight: "10px", 
                                      height: "18px", width: "18px"}} />
                                    Delete
                                  </StyledMenuItem>

                                  <StyledMenuItem onClick={handleClose}>
                                    <EditOutlined fontSize="12px" style={{marginRight: "10px", height: "18px", width: "18px"}} />
                                    Rename
                                  </StyledMenuItem>
                                  
                                  <StyledMenuItem onClick={handleClose}>
                                    <AddToPhotosOutlined fontSize="12px" style={{marginRight: "10px", height: "18px", width: "18px"}} />
                                    Duplicate
                                  </StyledMenuItem>
                                  <Divider style={{background: "rgba(179, 182, 183, 0.2)"}}/>
                                  <StyledMenuItem onClick={handleClose}>
                                    <NearMeOutlined fontSize="12px" style={{marginRight: "10px", height: "18px", width: "18px"}} />
                                    Digests
                                  </StyledMenuItem>
                                  <StyledMenuItem onClick={handleClose}>
                                    <TvOutlined fontSize="12px" style={{marginRight: "10px", height: "18px", width: "18px"}} />
                                    Enter TV Mode
                                  </StyledMenuItem>
                                  <StyledMenuItem onClick={handleClose}>
                                    <SettingsOutlined fontSize="12px" style={{marginRight: "10px", height: "18px", width: "18px"}} />
                                    Manage
                                  </StyledMenuItem>
                                  <Divider style={{background: "rgba(179, 182, 183, 0.2)"}}/>
                                  <StyledMenuItem onClick={handleClose}>
                                    <RefreshOutlined fontSize="12px" style={{marginRight: "10px", height: "18px", width: "18px"}} />
                                    Refresh Data
                                  </StyledMenuItem>
                                </Menu>
                              </Grid> */}
                            </Grid>
                          </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="simple tabs example"
                          centered
                          textcolor="primary"
                          style={{ backgroundColor: "transparent", color: "white", fontSize: "22px" }}
                        >
                          <Tab label="Sales" {...a11yProps(0)} />
                          <Tab label="Content" {...a11yProps(1)} />
                        </Tabs>
                      </Col>
                    </Row>
              </Col>
            </Row>
            
            <TabPanel value={value} index={0} component={React.Fragment}>
              <Row style={{ marginTop: "0.5%", marginBottom:"0.5%"}}>
                <Col md={12}>
                  <ToggleButtonGroup
                    exclusive
                    // style={{backgroundColor: 'white'}}
                    value={dateFilter}
                    onChange={(e, value) => {
                      if (value !== null)
                        setDateFilter(value)
                    }}
                    aria-label="text alignment"
                    color="primary"
                    size="medium"
                    // style={{float: "right"}}
                  >
                    <ToggleButton style={{borderColor:"rgba(179, 182, 183, 0.2)", backgroundColor: "#1c1c1c"}} value="last_30" aria-label="left aligned">
                      <Typography style={{textTransform: "none"}} color={dateFilter === "last_30" ? "primary": "default"}>
                        Last 30 Days{" "}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton style={{borderColor:"rgba(179, 182, 183, 0.2)", backgroundColor: "#1c1c1c"}} value="last_7" aria-label="centered">
                      <Typography style={{textTransform: "none"}} color={dateFilter === "last_7" ? "primary": "default"}>
                        Last 7 Days{" "}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton style={{borderColor:"rgba(179, 182, 183, 0.2)", backgroundColor: "#1c1c1c"}} value="today" aria-label="right aligned">
                      <Typography style={{textTransform: "none"}} color={dateFilter === "today" ? "primary": "default"}>
                        Today{" "}
                      </Typography>
                    </ToggleButton>
                    {/* <ToggleButton style={{borderColor:"rgba(179, 182, 183, 0.2)", backgroundColor: "#1c1c1c"}} value="last_month" aria-label="right aligned">
                      <Typography>
                        Custom{" "}
                      </Typography>
                    </ToggleButton> */}
                  </ToggleButtonGroup>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AnalyticMetricCard 
                    isLoading={analyticsLoader}
                    graphType="numChart" 
                    cardName="Orders"
                    contents={[
                      "Unique"
                    ]}
                    addOnData={{
                      "heading": "Total Orders",
                      "number": orderMetrics?.totalOrders,
                      "unit": "orders",
                    }}
                    data={{
                      "xs": 6,
                      "rightComponent": (
                        <React.Fragment>
                          <Grid item xs={6} container style={{padding: 30, marginTop: "10%"}}>
                            <Grid item>
                              {/* <Typography style={{color: "white"}}>
                                Total Orders  - {orderMetrics?.totalOrders}
                              </Typography> */}
                              <Typography style={{marginTop: 5, color: "#00ff38"}}>
                                via CreatorPay - {orderMetrics?.cpOrders}
                              </Typography>
                              <Typography style={{marginTop: 5, color: colors[0]}}>
                                via Google - {orderMetrics?.gOrders}
                              </Typography>
                              <Typography style={{marginTop: 5, color: colors[1]}}>
                                via Facebook - {orderMetrics?.fbOrders}
                              </Typography>
                              <Typography style={{marginTop: 5, color: colors[2]}}>
                                via others - {orderMetrics?.otherOrders}
                              </Typography>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )
                    }}
                    />
                </Col>
                <Col md={8}>
                  <AnalyticMetricCard graphType="tableChart" 
                    isLoading={analyticsLoader}
                    cardName="Order Info"
                    contents={[
                      "Orders in details"
                    ]}
                    data={{
                      "xs": 12,
                      'headerFilter': true,
                      'headerFilterComponent': getOrderInfoFilter()
                    }}
                    addOnData={{
                      "rows": orderFilterRows,
                      "columns": orderColumns
                    }}
                    />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AnalyticMetricCard 
                    isLoading={analyticsLoader}
                    graphType="productChart" 
                    graphData={productData?.mostViewed}
                    cardName="Most Viewed Products"
                    contents={[
                      "Unique",
                      "Top Events",
                    ]}
                    xSize={3}
                    ySize={8}
                    hoverCardName={null}
                    data={{"xs": 12}}
                    hoverWidth="40%"
                    xHeader="Product"
                    yHeader="Views"
                    differentColor={true}
                    totalValue={productData?.mostViewedCount}

                  />
                </Col>
                <Col md={6}>
                  <AnalyticMetricCard
                    isLoading={analyticsLoader}
                    graphType="productChart" 
                    graphData={productData?.mostBought}
                    cardName="Most Bought Products"
                    contents={[
                      "Unique",
                      "Top Events",
                    ]}
                    xSize={3}
                    ySize={8}
                    hoverCardName={null}
                    hoverWidth="40%"
                    data={{"xs": 12}}
                    xHeader="Product"
                    yHeader="Quantity"
                    differentColor={true}
                    totalValue={productData?.mostBoughtCount}
                    />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AnalyticMetricCard graphType="horizontalBar" 
                    isLoading={analyticsLoader}
                    graphData={graphData?.barGraph1}
                    data={{"xs": 12}}
                    // data={{xs: 12}}
                    contents={[
                      "2-step Funnel",
                      "The median time users spend every time they visit."
                    ]}
                    hoverWidth="50%"
                    xHeader="Time Span"
                    yHeader="Value"
                    addOnProgressLabel="%"
                    />
                </Col>

                <Col md={6}>
                  <AnalyticMetricCard 
                    isLoading={analyticsLoader}
                    cardName="Number of website visits per day"
                    contents={[
                      "Linear, total",
                      "How many visits users made to your website."
                    ]}
                    chartDataKey="No. of Sessions"
                    unitLabel="sessions"
                  graphType="lineChart" graphData={graphData?.lineGraph1} xAxisSkipNo={3} />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AnalyticMetricCard
                    isLoading={analyticsLoader}
                    cardName="Number of sessions from each city"
                    contents={[
                      "Unique",
                      "How many visits came from each city."
                    ]}
                    xHeader="City"
                    yHeader="Value"
                    graphType="horizontalBar1"
                    hoverCardName="Sessions"
                    totalValue={graphData?.barGraph2Count}
                    graphData={graphData?.barGraph2}
                    data={{xs: 12}}
                    differentColor={true} addOnData={{"heading": "Event", "component": (
                    <div style={{background: "#212529", padding: 5, borderRadius: "10px", maxWidth: "150px"}}>
                      <Typography style={{maxWidth: "130px",overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          textOverflow: "ellipsis", fontSize: "16px"}}>
                        Sessions
                      </Typography>
                      <Typography style={{fontSize: "12px"}}>
                        Overall {graphData?.totalCitySessions}
                      </Typography>
                    </div>
                  )}}/>
                </Col>
                <Col md={6}>
                  <AnalyticMetricCard graphType="numChart" 
                    isLoading={analyticsLoader}
                    cardName="Sessions Unique"
                    contents={[
                      "Unique"
                    ]}
                    addOnData={{
                      "heading": "App Session [Unique Users]",
                      "number": graphData?.uniqueSessions,
                      "unit": "users"
                    }}
                    />
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <AnalyticMetricCard
                    isLoading={analyticsLoader}
                  graphType="pieChart" graphData={graphData?.deviceData} cardName={"Device Details"} contents={[
                    "Number of user devices"
                  ]}/>
                </Col>
              </Row>
            </TabPanel>

            <TabPanel value={value} index={1} component={React.Fragment}>

              <Row style={{marginTop: "1%"}}>
                <Col md={4}>
                  <AnalyticMetricCard 
                    isLoading={analyticsLoader}
                    graphType="numChart" 
                    cardName="Content Reach"
                    contents={[
                      "Unique", "Total reach of content"
                    ]}
                    addOnData={{
                      "heading": "Total Reach",
                      "number": contentReachNumber,
                      "unit": "users",
                    }}
                    />
                </Col>
                <Col md={8}>
                  <AnalyticMetricCard graphType="tableChartCreator" 
                    isLoading={analyticsLoader}
                    cardName="Content Info"
                    contents={[
                      "Creator-wise content info"
                    ]}
                    data={{"xs": 12}}
                    addOnData={{
                      "rows": contentRows,
                      "columns": contentColumn
                    }}
                    />
                </Col>
              </Row>
            </TabPanel>
        </Container>
      </div>
    </>
  )
}

export default Analytics

export const AnalyticTableCard = ({
  data
}) => {

  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} container>

          {/* <Grid item xs={12}>
            <TableFilter />
          </Grid> */}

          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead> 
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Customer Details</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Order ID</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Date</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Line Items</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Total Price</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Discovery From</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Discount Coupon</TableCell>
                </TableHead> 
                <TableBody> 
                  {data?.rows?.length ? data?.rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                  <TableRow container alignItems="center">
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>
                      <div style={{fontWeight: "bolder"}}>
                        {row['name']}
                      </div>
                      <div style={{fontSize: "12px"}}>
                        {row['phoneNo']}{row['phoneNo'] ? <br /> : null}
                        {row['email'] ?? null}
                      </div>
                    </TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row["id"]}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5, minWidth: "100px"}}>{row["date"]}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row["lineItems"]}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row["price"]}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row["discoveredBy"]}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row["discountCode"]}</TableCell>

                  </TableRow>
                  )) : <TableRow>
                    <TableCell colSpan={7} style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5, marginTop: "15px"}} align="center">
                      No Data Found
                    </TableCell>
                    </TableRow>}
                </TableBody> 
              </Table>
            </TableContainer> 
            <TablePagination
                style={{color: "white"}}
                align="center"
                backIconButtonProps={{style: {color: "white"}}}
                nextIconButtonProps={{style: {color: "white"}}}
                SelectProps={{style: {color: "white"}}}
                rowsPerPageOptions={[
                  5,
                  10,
                  20,
                  50,
                  { label: "All", value: data?.rows?.length },
                ]}
                component="div"
                count={data?.rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => {
                  setPage(page)
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(e.target.value)
                  setPage(0)
                }}
              />
          </Grid>

      {/* <DataGrid
      style={{color: "white", maxWidth: "200%", width: "150%", border: "none"}}
      autoHeight
      rowClasses={{ root: 'hover-white' }}
        components={{
          pagination: {
            style: { color: 'white' },
          },
        }}

        rows={data?.rows}
        showCellRightBorder={false}
        showColumnRightBorder={false}
        columns={data?.columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // checkboxSelection
        disableSelectionOnClick
      />
      <style>
        {`
          .hover-white:hover {
            background-color: white !important;
          }
        `}
      </style> */}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}


export const AnalyticTableCardCreator = ({
  data
}) => {

  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} container>

          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead> 
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Creator</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Content</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Posted On</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Link shares</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Likes</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Comments</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Plays</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Saves</TableCell>
                  <TableCell item style={{fontSize: "14px", color: "white", padding: 5, margin: 5}}>Shares</TableCell>
                </TableHead> 
                <TableBody> 
                  {data?.rows?.length ? data?.rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                  <TableRow container alignItems="center">
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>
                      { row?.['creator'] ? <div style={{fontWeight: "bolder", cursor: "pointer", alignItems: "center"}} onClick={() => window.open(`https://www.instagram.com/${row?.['creator']?.['instaHandle']}`)}>
                        {row?.['creator']?.['username']} <Launch style={{fontSize: "16px", marginLeft: "5px"}}/>
                      </div> : "-"}
                    </TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row?.["reelLink"] ? (
                      <div style={{fontWeight: "bolder", cursor: "pointer", alignItems: "center"}} onClick={() => window.open(row?.['reelLink'])}>
                        Open Link <Launch style={{fontSize: "16px", marginLeft: "5px"}}/>
                      </div>
                    ) : "-"}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row?.["date"]?.split("T")?.[0]}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row?.["messageSentCount"] ?? "0"}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row?.['mediaData']?.['likes']?.['value'] ?? "-"}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row?.['mediaData']?.['comments']?.['value'] ?? "-"}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row?.['mediaData']?.['plays']?.['value'] ?? "-"}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row?.['mediaData']?.['saved']?.['value'] ?? "-"}</TableCell>
                    <TableCell item style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5}}>{row?.['mediaData']?.['shares']?.['value'] ?? "-"}</TableCell>

                  </TableRow>
                  )) : <TableRow>
                  <TableCell colSpan={10} style={{fontSize: "14px", color: "#AAB7B8", padding: 5, margin: 5, marginTop: "15px"}} align="center">
                      No Data Found
                    </TableCell>
                  </TableRow>}
                </TableBody> 
              </Table>
            </TableContainer> 
            <TablePagination
                style={{color: "white"}}
                align="center"
                backIconButtonProps={{style: {color: "white"}}}
                nextIconButtonProps={{style: {color: "white"}}}
                SelectProps={{style: {color: "white"}}}
                rowsPerPageOptions={[
                  5,
                  10,
                  20,
                  50,
                  { label: "All", value: data?.rows?.length },
                ]}
                component="div"
                count={data?.rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => {
                  setPage(page)
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(e.target.value)
                  setPage(0)
                }}
              />
          </Grid>

      {/* <DataGrid
      style={{color: "white", maxWidth: "200%", width: "150%", border: "none"}}
      autoHeight
      rowClasses={{ root: 'hover-white' }}
        components={{
          pagination: {
            style: { color: 'white' },
          },
        }}

        rows={data?.rows}
        showCellRightBorder={false}
        showColumnRightBorder={false}
        columns={data?.columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // checkboxSelection
        disableSelectionOnClick
      />
      <style>
        {`
          .hover-white:hover {
            background-color: white !important;
          }
        `}
      </style> */}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
