import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

function DateTypeToggle({ toggle, onChange }) {
  return (
    <ToggleButtonGroup
      exclusive
      value={toggle}
      onChange={(e, value) => {
        if (value !== null) onChange(value);
      }}
      aria-label="text alignment"
      color="secondary"
      style={{borderRadius: "15px"}}
    >
      <ToggleButton value="today" aria-label="left aligned" style={{color: "#AAB7B8", background: toggle === "today" ? "#2E4053": null}}>
        Today{" "}
      </ToggleButton>
      <ToggleButton value="week" aria-label="centered" style={{color: "#AAB7B8", background: toggle === "week" ? "#2E4053": null}}>
        Week{" "}
      </ToggleButton>
      <ToggleButton value="month" aria-label="right aligned" style={{color: "#AAB7B8", background: toggle === "month" ? "#2E4053": null}}>
        Month
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default DateTypeToggle;
