import React, { useCallback } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  Typography,
  TextField,
  Button,
  withStyles,
  IconButton,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  AppBar,
  Box,
} from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import {Autocomplete, createFilterOptions} from "@material-ui/lab"
import {
  Close
} from "@material-ui/icons"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import CustomAutocomplete from "../../Common/AutoComplete"
import GenericAnimation from "../../animation"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "moment"
import DateFnsUtils from "@date-io/date-fns" // choose your lib
import { RangeDatePicker } from "react-google-flight-datepicker"
import "react-google-flight-datepicker/dist/main.css"
import useKeyboardShortcut from "use-keyboard-shortcut"

const filter = createFilterOptions();


function FilterDialogForm({
  open, //should the dialog be opened
  setOpen = () => {}, //set the dialog open state
  autoClose = true, //close the dialog automatically if submit is clicked
  updateData = null, //new data to insert in the current sate of dialog
  dialogTitle = "", //title of the dialog
  maxWidth = "xs", //maximumm width of the dialog
  dialogProps = {}, //other props for the dialog
  dialogContent, //array of objects where an object signifies a textfield and has label and other props of the textfield
  message = "",
  footerMessage = "",
  fullWidth = false,
  handleClose = () => setOpen(false),
  tabs = [
    {
      label: dialogTitle,
      value: 0,
      dialogContent: dialogContent,
      message: message,
      footerMessage: footerMessage,
    },
  ],
  style = {},
  animationProps = null,
  showDialogAction = true,
  showSubmitButton = true,
  handleCancel = (val) => handleClose(val),
  handleSubmit,
  cancelButtonText = "Cancel",
  submitButtonText = "Submit",
  formData = null,
  isList = false,
  editListItem = false,
  removeListItem = false,
  onEditItem = null,
  onRemoveItem = null,
  showRequired = false,
  showOptional = false,
  defaultTabValue = 0,
  submitWithEnter = false,
}) {
  // useEffet(() => {
  //   setState((prev) => ({ ...prev, ...formData }))
  // }, [formData])
  const [tab, setTab] = useState(defaultTabValue)
  const [state, setState] = useState(() => (formData ? formData : {}))

  const getDialogContent = (field, index) => {
    switch (field?.type) {
      case "text": {
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") onSubmit();
                // }}
                fullWidth
                required={field.required}
                {...field?.inputProps}
                inputprops={{
                  maxLength: field.maxLength ? field.maxLength : "30",
                }}
                autoFocus={index === 0}
                placeholder={field?.placeholder}
                variant="outlined"
                disabled={
                  field.disabled
                    ? field.disabled
                    : field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false
                }
                value={state?.[field?.key]}
                onChange={(e) => {
                  if (field.onChange)
                    field?.onChange(e.target.value, state, setState)
                  else {
                    const temp = { ...state }
                    temp[field?.key] = e.target.value
                    setState({ ...temp })
                  }
                }}
                {...field.otherProps}
              />
            </Grid>
          </Grid>
        )
      }

      case "autocomplete":
        const onChange = (event, { newValue }) => {
          if (field.onChange) {
            field?.onChange(newValue, state, setState)
          } else {
            const temp = { ...state }
            temp[field?.key] = newValue
            setState({ ...temp })
          }
        }

        // Autosuggest will call this function every time you need to update suggestions.
        // You already implemented this logic above, so just use it.

        // Autosuggest will call this function every time you need to clear suggestions.
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomAutocomplete
                field={field}
                inputProps={{
                  placeholder: field.placeholder,
                  value: state?.[field?.key] ? state?.[field?.key] : "",
                  onChange: onChange,
                  disabled: field.disabled
                    ? field.disabled
                    : field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false,
                }}
              />
            </Grid>
          </Grid>
        )

      case "checkbox": {
        return (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              {field?.label ? (
                <FormLabel>
                  <Typography>
                    {field?.label}
                    <span style={{ fontSize: "14px", color: "grey" }}>
                      {" "}
                      {showRequired
                        ? field.required
                          ? "*"
                          : showOptional
                          ? "(Optional)"
                          : null
                        : null}
                    </span>
                  </Typography>
                </FormLabel>
              ) : null}
              <FormGroup>
                {field?.options?.map((v) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          state?.[field?.key]?.find(
                            (f) =>
                              f?.[field?.optionKey] === v?.[field?.optionKey]
                          )
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e?.target?.checked) {
                            const temp = { ...state }
                            temp[field?.key].push({ ...v })
                            setState({ ...temp })
                          } else {
                            const temp = { ...state }
                            temp[field?.key] = temp?.[field?.key]?.filter(
                              (f) =>
                                f?.[field?.optionKey] !== v?.[field?.optionKey]
                            )

                            setState({ ...temp })
                          }
                        }}
                        name={v?.[field?.optionKey]}
                        color={"primary"}
                      />
                    }
                    label={v?.[field?.optionKey]}
                    {...field.otherProps}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        )
      }
      case "date-range":
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RangeDatePicker
                startDate={state[field.key]?.startDate}
                endDate={state[field.key]?.endDate}
                onChange={(startDate, endDate) => {
                  const value = { startDate: startDate, endDate: endDate }
                  const temp = { ...state }
                  state[field.key] = value
                  setState({ ...temp })
                }}
                {...field.otherProps}
              />{" "}
            </Grid>
          </Grid>
        )

      case "date":
        return (
          <Grid item container xs={12} spacing={2 } md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  fullWidth
                  emptyLabel="Date of Appointment"
                  // maxDate={new Date("2021-07-31")}
                  inputVariant="outlined"
                  value={state[field?.key]}
                  onChange={(value) => {
                    if (field.onChange) {
                      field.onChange(value, state, setState)
                    } else {
                      const temp = { ...state }
                      temp[field?.key] = value
                      setState({ ...temp })
                    }
                  }}
                  variant="inline"
                  {...field.otherProps}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )

      case "select":
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") onSubmit();
                // }}
                fullWidth
                autoFocus={index === 0}
                disabled={
                  field.disabled
                    ? field.disabled
                    : field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false
                }
                select
                placeholder={field?.placeholder}
                variant="outlined"
                value={state?.[field?.key]}
                onChange={(e) => {
                  if (field.onChange)
                    field.onChange(e.target.value, state, setState)
                  else {
                    const temp = { ...state }
                    temp[field?.key] = e.target.value
                    setState({ ...temp })
                  }
                }}
                {...field.otherProps}
              >
                {field.options?.map((ele, idx) => (
                  <MenuItem key={idx} value={ele.value ? ele.value : ele}>
                    {ele.label ? ele?.label : ele}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )
    

    case "multi-autocomplete":
        return (
            <Grid item container spacing={1} xs={12} md={field?.md ? field?.md : 6}>
                <Grid item xs={12}>
                    <Typography>
                        {field?.label}
                        <span style={{ fontSize: "14px", color: "grey" }}>
                        {" "}
                        {showRequired
                            ? field.required
                            ? "*"
                            : showOptional
                            ? "(Optional)"
                            : null
                            : null}
                        </span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        fullWidth
                        onChange={(event, newValue) => {
                        let tempValue = ([...newValue].map((tag) => tag.inputValue ? tag.inputValue : tag))
                        if (field.onChange) {
                            field.onChange(tempValue, state, setState)
                        } else {
                            const temp = { ...state }
                            temp[field?.key] = tempValue
                            setState({ ...temp })
                        }
                        }}
                        value={state?.[field?.key] ? state?.[field?.key] : []}
                        multiple
                        filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '' && !field?.options?.some(v=> v.toLowerCase() === params.inputValue?.toLowerCase()) && 
                        !state?.[field?.key]?.some(v=> v.toLowerCase() === params.inputValue?.toLowerCase()) && field?.addOption){
                            filtered.push({
                            inputValue: params.inputValue,
                            title: `Add "${params.inputValue}"`,
                            });
                        }

                        return filtered;
                        }}
                        filterSelectedOptions
                        clearOnBlur
                        handleHomeEndKeys
                        options={field?.options}
                        getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.title;
                        }}
                        renderOption={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            } else {
                                return option.title;
                            }
                        }}
                        renderInput={(params) => (
                        <TextField fullWidth {...params} variant={field?.variant ? field?.variant : "outlined"} helperText={field?.helperText ? field?.helperText : ""} />
                        )}
                    />
                </Grid>
            </Grid>
          )

      default:
        return (
          <Grid item container xs={12} md={field?.md ? field?.md : 6}>
            <Grid item xs={12}>
              <Typography>
                {field?.label}
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {" "}
                  {showRequired
                    ? field.required
                      ? "*"
                      : showOptional
                      ? "(Optional)"
                      : null
                    : null}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") onSubmit();
                // }}
                fullWidth
                inputprops={{
                  maxLength: field.maxLength ? field.maxLength : "30",
                }}
                {...field?.inputProps}
                autoFocus={index === 0}
                disabled={
                  field.disabled
                    ? field.disabled
                    : field?.dependencyKey
                    ? !state?.[field?.dependencyKey]
                    : false
                }
                type={field.type ? field.type : "text"}
                placeholder={field?.placeholder}
                variant="outlined"
                value={state?.[field?.key]}
                onChange={(e) => {
                  if (field.onChange)
                    field?.onChange(e.target.value, state, setState)
                  else {
                    const temp = { ...state }
                    temp[field?.key] = e.target.value
                    setState({ ...temp })
                  }
                }}
                {...field.otherProps}
              ></TextField>
            </Grid>
          </Grid>
        )
    }
  }
  const onSubmit = () => {
    let error = {}
    if (tabs[tab]?.dialogContent?.length) {
      const checkDialogContent = [...tabs[tab]?.dialogContent]
      checkDialogContent?.reverse()
      checkDialogContent?.map((v) => {
        if (v?.required && !state?.[v?.key] && !state?.[v?.key]?.length) {
          error = {
            check: true,
            message: `${v?.label ? v?.label : v?.errorKey} is required`,
          }
        }
      })
    }
    if (!error?.check) {
      if (autoClose) onClose()
      handleSubmit(state, tab)
    } else toast.error(error?.message)
  }

  const onClose = (type = null) => {
    setState({})
    if (type === "cancel") handleCancel(true)
    else handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={{
        style: {
          borderRadius: "15px",
          minWidth: "300px",
          overflowY: "auto",
        },
      }}
      {...dialogProps}
    >
      <DialogTitle onClose={onClose}>
        <Typography variant="h6" style={{fontWeight: "bold"}}>{dialogTitle}</Typography>
      </DialogTitle>
      
        <DialogContent
        dividers
          style={{ overflowY: "visible", position: "relative" }}
        >
                 
            <Grid container spacing={2} alignItems="baseline">
            {dialogContent?.map((v, index) =>
                getDialogContent(v, index)
            )}
            </Grid>
        </DialogContent>
        {showDialogAction ? (
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onClose("cancel")}
          >
            {cancelButtonText}
          </Button>
          {showSubmitButton ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmit()}
            >
              {submitButtonText}
            </Button>
          ) : null}
        </DialogActions>
      ) : null}
    </Dialog>
  )
}


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
},
})

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>

      {onClose ? (
          <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
function TabPanel(props) {
    const { children, value, index, ...other } = props
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
      {value === index && (
          <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    }
}

export default FilterDialogForm