import {
    Avatar,
    Backdrop,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
    Button,
    makeStyles,
    Tooltip,
    Tabs,
    Tab,
    Box,
    Chip,
    InputAdornment,
  } from "@material-ui/core"
  import Linkify from "react-linkify"
  import parse from "html-react-parser"
  import React, { useEffect, useRef, useState } from "react"
  import doctorSocket from "../../../components/Dashboard/Home/AddAppointmentDialog/doctorSocket"

  import uuid from "uuid";
  
  import { colors } from "../../../assets/css/colors"
  import dashboardSocket from "../../../socket/dashboardSocket"
  import LoaderFullpage from "../../Common/LoaderFullpage"
  import moment from "moment"
  import customAxios from "../../../axios"
  import { toast } from "react-toastify"
  import GenericDialogForm from "../../CreatePrescription/GenericDialog"
  import { useHistory } from "react-router"
  import { AddBoxTwoTone, Cancel, Clear, Close, Delete, Done, Edit, KeyboardReturn, MailOutline, Remove, SettingsInputComponent, TagFaces } from "@material-ui/icons"
  import whatsappBg from "./../../../assets/img/chatWindow/whatsappBg.png"
  import { Autocomplete, SpeedDial, SpeedDialAction, SpeedDialIcon, createFilterOptions } from "@material-ui/lab"
  import {
    convertStringToHtml,
    createElementFromHTML,
    getRandomColor,
  } from "../../../views/helper"
  import { format, whatsappRules } from "@flasd/whatsapp-formatting"
  import mixpanel from "mixpanel-browser"
import Add from "@material-ui/icons/Add"
import { useSelector } from "react-redux"

const filter = createFilterOptions();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }
  
  function ChatDrawer({ ...props }) {
    const history = useHistory()
    const {
      patient,
      setPatient,
      setOpenDrawer,
      isDoctor
    } = props
    const { _id: id } = patient
    
    const user = useSelector((state) => state.user.user)

    const [contactData, setContactData] = useState({})

    const [tagOptions, setTagOptions] = useState([])
    useEffect(() => {
        customAxios
        .get("/contacts/all-tags")
        .then((res) => {
            if(res.data?.data)
            setTagOptions(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }, [conversationTags, contactTags])

    const toastId = useRef(null)

    const [value, setValue] = React.useState('conversation');

    
    const [attributes, setAttributes] = useState([])
    
    const [conversationTags, setConversationTags] = useState([]);
    const [notes, setNotes] = useState([]);
    const [buckets, setBuckets] = useState([]);
    const [noteState, setNoteState] = useState("");
    const [contactTags, setContactTags] = useState([])
    const [assignedUsers, setAssignedUsers] = useState([{"_id": user?._id, "name": user?.name, "default": true}])
    
    const [editOption, setEditOption] = useState({
        "conversation": false,
        "contact": false,
        "notes": false,
        "info": false,
        "assigned": false
    })
    
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        customAxios.get("/user/list").then((res) => {
            if(res?.data?.success) {
                setUserList(res.data.data ? res.data.data : [])
            }
        })
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        customAxios.get(`/contacts/data/${id}`).then((res) => {
            if(res.data.success) {
                setContactData(res.data.data)
                setConversationTags(res.data.data?.conversationTags ? res.data.data?.conversationTags : [])
                setContactTags(res.data.data?.tags ? res.data.data?.tags : [])
                setAssignedUsers(res.data.data?.assignedUsers ? res.data.data?.assignedUsers : [{"_id": user?._id, "name": user?.name, "default": true}])
                setNotes(res.data.data?.notes ? res.data.data?.notes : [])
                setAttributes(res.data.data?.attributes ? res.data.data?.attributes : [])
                setBuckets(res.data?.data?.buckets ? res.data?.data?.buckets : [])
            }
        })

    }, [])

    const handleOnSubmit = (key, addOn = "", updatedData = null) => {
        let data = {
            "contactId": id
        }
        let inContact = false

        switch(key) {
            case "conversation": {
                data['keyValue'] = {
                    "value": conversationTags
                }
                data['keyName'] = "conversationTags"
                break;
            }

            case "contact": {
                data['keyValue'] = {
                    "value": contactTags
                }
                data['keyName'] = "tags"
                break;
            }

            case "notes": {
                data['keyValue'] = {
                    "value": updatedData
                }
                data['keyName'] = "notes"
                break;
            }

            case "attributes": {
                data['keyValue'] = {
                    "value": attributes
                }
                data['keyName'] = "attributes"
                break;
            }

            case "assigned": {
                data['keyValue'] = {
                    "value": assignedUsers
                }
                data['keyName'] = "assignedUsers"
                break;
            }

            case "info": {
                data['inContact'] = {
                    "name": patient?.name,
                    "email": patient?.email
                }
                inContact = true
                break;
            }

            default: console.log("default")
        }

        if (addOn === 'delete' && data?.keyValue?.value) {
            data.keyValue.value = updatedData
        }
        customAxios.put(`/contacts/data/${id}?in_contact=${inContact}`, data).then((res) => {
            if(res.data.success) {
                console.log("updated", key)
                
                if(key === 'attributes' && addOn === '') 
                    toast.success("Update Successfully")
                else
                    setEditOption({
                        ...editOption,
                        [key]: false
                    });
            }
        })
    }

    const updateContactBucket = (bucketData, index)  => {
        customAxios.put("/contacts/bucket", {...bucketData, contactId: id}).then((res) => {
            if(!res?.data?.success) {
                toast.error(res?.data?.message)
            } else {
                console.log("update success", res?.data?.data)
                let newBuckets = [...buckets];
                newBuckets[index]['bucketId'] = res?.data.data;
                setBuckets(newBuckets);
            }
        })
    }

    const updateBucket = (bucketId, key, value) => {
        let updatedData = {};
        updatedData[key] = value;
        customAxios.post(`/organisation/bucket/${bucketId}`, {...updatedData}).then((res) => {
            if(!res?.data?.success) {
                console.log("error in updating bucket", res?.data?.message)
            }
        })
    }

  
    return (
    <>
        <Grid item xs={12}>
            <Grid container xs={12} style={{ margin: "1rem"}}>
                <Grid item xs={11}><IconButton style={{float: "right"}} onClick={() => setOpenDrawer(false)}><Close /></IconButton></Grid>
                <Grid item container xs={12} justifyContent='center' style={{ fontFamily: "Inter !important" }}>
                    <Typography variant="h6">
                    <b>{patient?.name && !patient?.name?.includes(+91) ? patient?.name : "No name"}</b>
                    </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="center">
                    <Typography style={{ fontSize: "14px", fontWeight: "500" }}>{isDoctor ? `+91-${patient.phoneNo}`: "+91-xxxx"}</Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="center">
                <Tabs
                    value={value}
                    indicatorcolor="primary"
                    textcolor="primary"
                    onChange={handleChange}
                    style={{padding: "0", width: "100%"}}
                >
                    <Tab label="Conversation" style={{width:'50%'}} value={"conversation"} />
                    <Tab label="Contact" style={{width: "50%"}} fullWidth value={"contact"} />
                </Tabs>
            </Grid>
            <TabPanel value={value} index={"conversation"}> 
                <Grid container xs={12} spacing={1} alignItems="center" style={{overflowY: "auto"}}>
                    <Grid item xs={10}>
                        <Typography style={{fontSize: "14px", fontWeight: 'bold'}}>
                            Conversation Tags
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton size="small" onClick={() => setEditOption({...editOption, "conversation": true})}>
                            <Add />
                        </IconButton>
                    </Grid>
                    {!editOption?.conversation ? <Grid item xs={12}>
                        {conversationTags?.length ? conversationTags.map((tag, index) => (
                            <Chip style={{borderRadius: 10, padding: 5, opacity: "0.7", fontWeight: "600", marginRight: 5, backgroundColor: "#AED6F1"}} label={tag} 
                                deleteIcon={<Clear />} onDelete={() => {
                                let newTags = conversationTags.filter((t, i) => i !== index)
                                setConversationTags([...newTags])
                                handleOnSubmit("conversation", 'delete', newTags)
                            }} variant="outlined"/>
                            )) : "No tags added"}
                    </Grid> : 
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={10}>
                            <Autocomplete
                                fullWidth
                                onChange={(event, newValue) => {
                                setConversationTags([...newValue].map((tag) => tag.inputValue ? tag.inputValue : tag))
                                }}
                                value={conversationTags ? conversationTags : []}
                                multiple
                                openOnFocus
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    
                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '' && !tagOptions?.some(v=> v.toLowerCase()?.startsWith(params.inputValue?.toLowerCase())) && 
                                !conversationTags?.some(v=> v.toLowerCase()?.startsWith(params.inputValue?.toLowerCase()))){
                                    filtered.push({
                                    inputValue: params.inputValue,
                                    title: (<Grid container>
                                        <Grid item xs={11}>
                                            <Typography>
                                                {`Add "${params.inputValue}"`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <KeyboardReturn style={{float: "right"}}/>
                                        </Grid>
                                    </Grid>),
                                    });
                                }

                                return filtered;
                                }}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                      <Chip
                                        label={option.title ? option.title : option ? option : ""}
                                        {...getTagProps({ index })}
                                        disabled={tagOptions.indexOf(option) !== -1}
                                      />
                                    ))
                                  }
                                filterSelectedOptions
                                clearOnBlur
                                handleHomeEndKeys
                                onKeyPress={(e ) => {
                                    if (e.key.toLowerCase() === "enter"){
                                        let currentValue = e?.target?.value
                                        if (currentValue && currentValue?.length && typeof currentValue === "string")
                                            setConversationTags([...conversationTags, currentValue])
                                        handleOnSubmit("conversation")
                                    }
                                }}
                                options={tagOptions}
                                getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.title;
                                }}
                                renderOption={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    } else {
                                        return option.title;
                                    }
                                }}
                                renderInput={(params) => (
                                <TextField fullWidth {...params} autoFocus focused label="Select tags.." helperText="Select Tags or Create a new one" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton size="medium"  onClick={() => handleOnSubmit("conversation")}>
                                <Done />
                            </IconButton>
                        </Grid>
                    </Grid>}
                    <Grid item xs={12} style={{marginTop: "10px"}}></Grid>
                    <Grid item xs={10}>
                        <Typography style={{fontSize: "14px", fontWeight: 'bold'}}>
                            Assigned To
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton size="small" onClick={() => setEditOption({...editOption, "assigned": true})}>
                            <Add />
                        </IconButton>
                    </Grid>
                    <Grid item container spacing={1} alignItems="center" xs={12}>
                        <Grid item xs={12}>
                            {assignedUsers?.length ? assignedUsers.map((value, index) => (
                                <Chip style={{margin: 5, backgroundColor: "#5D6D7E", color: "white", fontWeight: "600", fontSize: 16}} avatar={<Avatar style={{color: "white"}}>{value?.name?.slice(0, 1)}</Avatar>} label={value?.name}/>
                                )) : "No Users assigned"}
                        </Grid>
                        {editOption?.assigned ? <>
                            <Grid item xs={10}>
                                <Autocomplete
                                    fullWidth
                                    onChange={(event, newValue) => {
                                    setAssignedUsers([...newValue])
                                    }}
                                    value={assignedUsers}
                                    multiple
                                    filterSelectedOptions
                                    clearOnBlur
                                    handleHomeEndKeys
                                    openOnFocus
                                    onKeyPress={(e) => {
                                        if (e?.key?.toLowerCase() === "enter"){
                                            handleOnSubmit("assigned")
                                        }
                                    }}
                                    options={userList}
                                    getOptionLabel={(option) => option?.name}
                                    renderOption={(option) => option?.name}
                                    renderInput={(params) => (
                                    <TextField fullWidth {...params} label="Select User" variant="outlined" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton size="medium"  onClick={() => handleOnSubmit("assigned")}>
                                    <Done />
                                </IconButton>
                            </Grid>
                        </> : null}
                    </Grid>
                        
                        {buckets?.length ? buckets.map((bucket, index) => (
                            <>
                                {/* <Grid item xs={6}>
                                    <TextField variant="standard" value={bucket?.name} onChange={(e) => {
                                        let newBuckets = [...buckets];
                                        newBuckets[index].name = e.target.value;
                                        setBuckets(newBuckets);
                                        }}
                                        InputProps={{endAdornment: !bucket?.bucketId ? (
                                            <InputAdornment position="end">
                                                <IconButton size="small" onClick={() => {
                                                    let newBuckets = [...buckets];
                                                    newBuckets.splice(index, 1);
                                                    setBuckets(newBuckets);
                                                }}>
                                                    <Delete />
                                                </IconButton>
                                            </InputAdornment>) : null
                                        }}
                                        onBlur={(e) => {
                                            if(bucket?.bucketId){
                                                updateBucket(bucket?.bucketId, "name", bucket['name']);
                                            }
                                        }}
                                    />
                                </Grid> */}
                                <Grid item container xs={10} alignItems="center">
                                    <Grid item xs={8} style={{alignItems: "center", display: "flex"}}>
                                        {!bucket?.editName ? <Typography style={{fontSize: "14px", fontWeight: 'bold'}}>
                                            {bucket?.name}
                                        </Typography> : (
                                            <TextField variant="standard" style={{width: "80px"}} autoFocus value={bucket?.name} onChange={(e) => {
                                                let newBuckets = [...buckets];
                                                newBuckets[index].name = e.target.value;
                                                setBuckets(newBuckets);
                                                }}
                                                // InputProps={{endAdornment: !bucket?.bucketId ? (
                                                //     <InputAdornment position="end">
                                                //         <IconButton size="small" onClick={() => {
                                                //             let newBuckets = [...buckets];
                                                //             newBuckets.splice(index, 1);
                                                //             setBuckets(newBuckets);
                                                //         }}>
                                                //             <Delete />
                                                //         </IconButton>
                                                //     </InputAdornment>) : null
                                                // }}
                                                // onBlur={(e) => {
                                                //     if(bucket?.bucketId){
                                                //         updateBucket(bucket?.bucketId, "name", bucket['name']);
                                                //     }
                                                // }}
                                            />
                                        )}
                                        
                                        <IconButton onClick={() => {
                                            let newBuckets = [...buckets];
                                            newBuckets[index].editName = !newBuckets[index]?.editName;
                                            setBuckets(newBuckets);

                                            if(bucket?.bucketId && !newBuckets[index]?.editName) {
                                                updateBucket(bucket?.bucketId, "name", bucket['name']);
                                            }
                                        }}>
                                            {!bucket?.editName ? <Edit /> : <Done />}
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={2} style={{marginLeft: "auto"}}>
                                        {!bucket?.bucketId ? <IconButton size="small" onClick={() => {
                                            let newBuckets = [...buckets];
                                            newBuckets.splice(index, 1);
                                            setBuckets(newBuckets);
                                        }}>
                                            <Delete />
                                        </IconButton> : null}
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={2}>
                                    
                                </Grid> */}
                                {/* <Grid item xs={4}></Grid> */}
                                <Grid item xs={2}>
                                    <IconButton size="small" onClick={() => {
                                        let newBuckets = [...buckets];
                                        newBuckets[index].edit = !newBuckets[index].edit;
                                        setBuckets(newBuckets);
                                    }}>
                                        <Add />
                                    </IconButton>
                                </Grid>
                                {!bucket?.edit ? <Grid item xs={12}>
                                    {bucket?.tags?.length ? bucket?.tags?.map((tag, tagIndex) => (
                                        <Chip style={{margin: 5}} label={tag} onDelete={() => {
                                            let newBuckets = [...buckets];
                                            let newTags = bucket?.tags?.filter((t, i) => i !== tagIndex)
                                            newBuckets[index].tags = [...newTags];
                                            setBuckets(newBuckets);
                                            updateContactBucket(newBuckets[index], index)
                                            // handleOnSubmit("conversation", 'delete', newTags)
                                        }}/>
                                        )) : "No tags added"}
                                </Grid> : 
                                <Grid item container spacing={1} xs={12}>
                                <Grid item xs={10}>
                                    <Autocomplete
                                        fullWidth
                                        onChange={(event, newValue, reason) => {
                                            
                                            let newBuckets = [...buckets];
                                            newBuckets[index].tags = [...newValue].map((tag) => tag.inputValue ? tag.inputValue : tag)
    
                                            let inputValue = [...newValue].find((tag) => tag.inputValue)?.inputValue;
                                            
                                            if (inputValue)
                                                newBuckets[index].tagOptions?.push(inputValue);
    
                                            if (inputValue && bucket?.bucketId){
                                                updateBucket(bucket?.bucketId, "tagOptions", newBuckets[index].tagOptions);
                                            }
    
                                            if(reason === "select-option") {
                                                newBuckets[index].edit = false;
                                                setBuckets(newBuckets);
                                                updateContactBucket(bucket, index)
                                            }
    
                                            setBuckets(newBuckets);
                                        }}
                                        value={bucket?.tags ? bucket?.tags : []}
                                        multiple
                                        filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
    
                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '' && !bucket?.tagOptions?.some(v=> v.toLowerCase()?.startsWith(params.inputValue?.toLowerCase())) && 
                                        !bucket?.tags?.some(v=> v.toLowerCase()?.startsWith(params.inputValue?.toLowerCase()))){
                                            filtered.push({
                                            inputValue: params.inputValue,
                                            title: (<Grid container>
                                            <Grid item xs={11}>
                                                <Typography>
                                                    {`Add "${params.inputValue}"`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <KeyboardReturn style={{float: "right"}}/>
                                            </Grid>
                                        </Grid>),
                                            });
                                        }
    
                                        return filtered;
                                        }}
                                        filterSelectedOptions
                                        // size={"s"}
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onKeyPress={(e) => {
                                            if (e.key.toLowerCase() === "enter"){
                                                let newBuckets = [...buckets];
                                                let currentValue = e?.target?.value
                                                newBuckets[index].tags = [...newBuckets[index].tags, currentValue]
                                                if(currentValue && typeof currentValue === "string" && !bucket?.tagOptions?.find(f => f?.toLowerCase() === currentValue.toLowerCase())) {
                                                    let inputValue = currentValue;
                                                    
                                                    if (inputValue)
                                                        newBuckets[index].tagOptions?.push(inputValue);
    
                                                    if (inputValue && bucket?.bucketId){
                                                        updateBucket(bucket?.bucketId, "tagOptions", newBuckets[index].tagOptions);
                                                    }
                                                    setBuckets(newBuckets);
                                                }
                                                newBuckets[index].edit = false;
                                                setBuckets(newBuckets);
                                                updateContactBucket(bucket, index)
                                            }
                                        }}
                                        options={bucket?.tagOptions}
                                        openOnFocus={bucket?.tagOptions?.length}
                                        getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.title;
                                        }}
                                        renderOption={(option) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            } else {
                                                return option.title;
                                            }
                                        }}
                                        renderInput={(params) => (
                                        <TextField fullWidth {...params} autoFocus focused label="Select tags.." helperText="Select Tags or Create a new one" variant="outlined" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton size="medium"  onClick={() => {
                                        let newBuckets = [...buckets];
                                        newBuckets[index].edit = false;
                                        setBuckets(newBuckets);
                                        updateContactBucket(bucket, index)
                                        // handleOnSubmit("conversation", 'add', bucket?.tags)
                                    }}>
                                        <Done />
                                    </IconButton>
                                </Grid>
                            </Grid>}
                            <Grid item xs={12} style={{marginTop: "10px"}}></Grid>
                        </>
                        )) : null}
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}> 
                            <Button variant="outlined" color="primary" startIcon={<Add />} fullWidth onClick={() => {
                                let newBuckets = [...buckets];
                                newBuckets.push({"name": `Bucket${buckets?.length+1}`, "tags": [], "edit": true, "tagOptions": [], "editName": true});
                                setBuckets(newBuckets);
                            }}>
                                Add New Bucket
                            </Button>
                        </Grid>
                    <Grid item xs={12} style={{marginTop: "10px"}}></Grid>

                    <Grid item xs={10}>
                        <Typography style={{fontSize: "14px", fontWeight: 'bold'}}>
                            Notes
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton size="small" onClick={() => setEditOption({...editOption, "notes": true})}>
                            <Add /> 
                        </IconButton>
                    </Grid>
                    <Grid item container spacing={1} alignItems="center" xs={12}>
                        {notes?.length ? notes.map((value, index) => (
                            <Grid item xs={12}>
                                <Chip avatar={<MailOutline/>} label={value?.text?.length > 28 ? value?.text?.slice(0, 28)+".." : value?.text} onDelete={() => {
                                    let newNotes = notes.filter((t, i) => i !== index)
                                    setNotes([...newNotes])
                                    handleOnSubmit("notes", 'delete', [...newNotes])
                                }}/>
                            </Grid>
                        )) : null}
                        {editOption?.notes ? <>
                            <Grid item xs={10}>
                                <TextField fullWidth label="Add Note" multiline variant="outlined" onChange={(e) => setNoteState(e?.target?.value)} value={noteState} />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton size="medium"  onClick={() => {
                                    if(noteState?.length)
                                        setNotes([...notes, {text: noteState}])
                                    handleOnSubmit("notes", 'add', [...notes, {text: noteState}])
                                    setNoteState("")
                                    }}>
                                    <Done />
                                </IconButton>
                            </Grid>
                        </> : null}
                    </Grid>
                    <Grid item xs={12} style={{marginTop: "10px"}}></Grid>

                    
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={"contact"}>
                <Grid container spacing={2} xs={12}>
                    <Grid item xs={10}>
                        <Typography style={{fontSize: "14px", fontWeight: 'bold'}}>
                            Information
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton size="small" onClick={() => {
                            setEditOption({...editOption, "info": !editOption?.info})
                            if(editOption?.info)
                                handleOnSubmit("info")
                            }}>
                            {!editOption?.info ? <Edit /> : <Done />}
                        </IconButton>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={6}>
                            <Typography style={{fontSize: "15px"}}>Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {!editOption?.info ?
                                <Typography style={{fontSize: "15px"}}>{patient?.name && !patient?.name?.includes("+91") ? patient?.name : "No name"}</Typography>
                                : <TextField fullWidth variant="standard" onChange={(e) => setPatient({...patient, name: e?.target?.value})} value={patient?.name && !patient?.name?.includes("+91") ? patient?.name : "No name"} />
                            }
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={6}>
                            <Typography style={{fontSize: "15px"}}>Email</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {!editOption?.info ?
                                <Typography style={{fontSize: "15px"}}>{patient?.email}</Typography>
                                : <TextField fullWidth variant="standard" onChange={(e) => setPatient({...patient, email: e?.target?.value})} value={patient?.email} />
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid item xs={10}>
                        <Typography style={{fontSize: "14px", fontWeight: 'bold'}}>
                            Atributes
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton size="small" onClick={() => setAttributes([...attributes, {}])}>
                            <Add />
                        </IconButton>
                    </Grid>
                    <Grid item container spacing={2} alignItems='center' xs={12}>
                        {attributes?.length ? attributes.map((value, index) => (
                            <><Grid item xs={5}>
                                <TextField fullWidth variant="outlined" size="small" onChange={(e) => {
                                    let newAttributes = attributes;
                                    newAttributes[index].key = e?.target?.value;
                                    setAttributes([...newAttributes])
                                }} value={value?.key} />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth variant="outlined" size="small" onChange={(e) => {
                                    let newAttributes = attributes;
                                    newAttributes[index].value = e?.target?.value;
                                    setAttributes([...newAttributes])
                                }} value={value?.value} />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton size="small" onClick={() => {
                                    handleOnSubmit("attributes")
                                }}>
                                    <Done fontSize="small"/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton size="small" onClick={() => {
                                    let newAttributes = attributes.filter((t, i) => i !== index)
                                    setAttributes([...newAttributes])
                                    handleOnSubmit("attributes", "delete", [...newAttributes])
                                }}>
                                    <Delete fontSize="small"/>
                                </IconButton>
                            </Grid>
                            </>
                        )) : "No attributes added"}
                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid item xs={10}>
                        <Typography style={{fontSize: "14px", fontWeight: 'bold'}}>
                            Contact Tags
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton size="small" onClick={() => setEditOption({...editOption, "contact": true})}>
                            <Add />
                        </IconButton>
                    </Grid>
                    {!editOption?.contact ? <Grid item xs={12}>
                        {contactTags?.length ? contactTags.map((tag, index) => (
                            <Chip style={{margin: 5}} label={tag} onDelete={() => {
                                let newTags = contactTags.filter((t, i) => i !== index)
                                setContactTags([...newTags])
                                handleOnSubmit("contact", "delete", [...newTags])
                            }}/>
                        )) : "No tags added"}
                    </Grid> : <Grid item container spacing={1} xs={12}>
                        <Grid item xs={10}>
                            <Autocomplete
                                fullWidth
                                onChange={(event, newValue) => {
                                setContactTags([...newValue].map((tag) => tag.inputValue ? tag.inputValue : tag))
                                }}
                                value={contactTags ? contactTags : []}
                                multiple
                                filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                // Suggest the creation of a new value
                                if (params.inputValue !== '' && !tagOptions?.some(v=> v.toLowerCase()?.startsWith(params.inputValue?.toLowerCase())) && 
                                !conversationTags?.some(v=> v.toLowerCase()?.startsWith(params.inputValue?.toLowerCase()))){
                                    filtered.push({
                                    inputValue: params.inputValue,
                                    title: (<Grid container>
                                        <Grid item xs={11}>
                                            <Typography>
                                                {`Add "${params.inputValue}"`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <KeyboardReturn style={{float: "right"}}/>
                                        </Grid>
                                    </Grid>),
                                    });
                                }

                                return filtered;
                                }}
                                onKeyPress={(e) => {
                                    if (e.key.toLowerCase() === "enter"){
                                        let currentValue = e?.target?.value
                                        if (currentValue && currentValue?.length && typeof currentValue === "string")
                                            setContactTags([...contactTags, currentValue])
                                        handleOnSubmit("contact")
                                    }
                                }}
                                openOnFocus
                                filterSelectedOptions
                                clearOnBlur
                                handleHomeEndKeys
                                options={tagOptions}
                                getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.title;
                                }}
                                renderOption={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    } else {
                                        return option.title;
                                    }
                                }}
                                renderInput={(params) => (
                                <TextField fullWidth {...params} autoFocus focused label="Select tags.." helperText="Select Tags or Create a new one" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton size="medium"  onClick={() => handleOnSubmit("contact")}>
                                <Done />
                            </IconButton>
                        </Grid>
                    </Grid> }
                </Grid>
            </TabPanel>
        </Grid>
</>
    )
  }
  
  export default ChatDrawer
  