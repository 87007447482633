import {
    TablePagination,
    Button,
    IconButton,
    Typography,
    Grid,
    CardContent,
    TextField,
    Divider,
    FormControlLabel,
    Switch,
    InputAdornment,
    Chip,
    makeStyles,
} from "@material-ui/core"
import moment from "moment"

import React, { useEffect, useState } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { Card, CardHeader, Table, Row, Col, Container } from "reactstrap"

import Input from "reactstrap/lib/Input"

import { useSelector } from "react-redux"

import customAxios from "../../axios"
import LoadingAnimation from "../../views/pages/examples/LoadingAnimation"
import FileUploadModal from "../Common/FileUploadModal"
import GenericDialogForm from "../CreatePrescription/GenericDialog"
import whatsappLogo from "./../../assets/img/icons/common/whatsapp.png"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import { Add, DateRange } from "@material-ui/icons"
import DateTime from "react-datetime"
import { DateTimePicker } from '@material-ui/pickers'
import { toast } from 'react-toastify';

import { useRef } from "react"
import Header from "../User/Header"
import LoaderFullpage from "../Common/LoaderFullpage"
import Lottie from 'react-lottie'
import animationData from '../../assets/animationData/29751-social-media-marketing.json'


const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  textField: {
    color: '#AAB7B8', // Change the color of the placeholder text
    opacity: 1,
    // fontWeight: "bold",
    borderColor: "#AAB7B8 !important"
  },
}))

function AddNewBroadcast({}) {
  const history = useHistory()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    renderer: 'svg',
  };
  



  return (
    <React.Fragment>
    <Header name={"Add New Broadcast"} parentName={"Broadcast"} onParentClick={() => history.goBack()}/>
    <Container className="mt--6" fluid>
    <Col className="mt-2">
      <Card>
        <CardHeader className="border-0">
          <Row style={{ alignItems: "center" }}>
            <Col md="10">
              <h3 className="mb-0">Add New Broadcast</h3>
            </Col>{" "}
          </Row>
        </CardHeader>
        <Divider />
        <CardContent>
          <Grid container spacing={2} style={{margin: 10}} alignItems='center'>
            <Grid item xs={12} md={5}>
              <Lottie
                  options={defaultOptions}
                  height={"85%"}
                  width={"85%"}
                  isStopped={false}
                  isPaused={false}
                />
            </Grid>
            <AddNewBroadcast1 />
          </Grid>
        </CardContent>
        
      </Card>
    </Col>
    </Container>
    </React.Fragment>
  )
}

export function AddNewBroadcast1({ onlyAdd = false }) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  
  const documentsRef = useRef();
  const [advanceOption, setAdvanceOptions] = useState(false)
  const [skipDayValue, SkipDayValue] = useState(0)
  const [state, setState] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const [broadcastState, setBroadcastState] = useState({})

  const classes1 = useStyles()

  const handleUpload = async (files) => {
    const tempArr = []
    if (files) {
      for (const file of files) {
        let reader = new FileReader()

        reader.onload = function (e) {
          tempArr.push({
            file: e.target.result,
            name: file.name,

            type: "Addtional Docs",
            extension: file.type,
          })
          
          console.log(tempArr, "temparr")
          setState([...tempArr])
          setBroadcastState({...broadcastState, document: {...tempArr[0]}})
        }

        reader.readAsDataURL(file)
      }
    }
  }

  const [templateList, setTemplateList] = useState([]);

  useEffect(() => {
    customAxios
      .get("/wa-template/list")
      .then((res) => {
        setTemplateList(res.data.data?.filter((v) => v?.status?.toLowerCase() === 'approved'));
      })
      .catch((err) => {
        console.log(err);
      });
    
    customAxios
    .get("/contacts/all-tags")
    .then((res) => {
      if(res.data?.data)
        setTagOptions(res.data.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])

  const createBroadcast = () => {
    if (!broadcastState?.name) {
      toast.error("Please enter broadcast name")
      return
    }
    if (!broadcastState?.templateData) {
      toast.error("Please select whatsapp template")
      return
    }
    if (!broadcastState?.document) {
      toast.error("Please upload csv file")
      return
    }
    setLoading(true)
    customAxios.post("/broadcast/", broadcastState)
    .then((res) => {
      if(res.data?.success){
        setLoading(false)
        toast.success("Broadcast created successfully")
        if(!onlyAdd)
        history.goBack()
      }
      else {
        toast.error(res.data?.message ? res.data?.message : "Something went wrong")
        setLoading(false)
      }
    })
    .catch((err) => {
      toast.error("Something went wrong")
      setLoading(false)
      console.log(err)
    })
  }


  return (
    <React.Fragment>

    {!onlyAdd ? <LoaderFullpage loading={loading} /> : null}
    <input
      onChange={(e) => {
        handleUpload(e.target.files)
      }}
      hidden
      label="additionalFiles"
      ref={documentsRef}
      title="FilesUploader"
      type="file"
      multiple={false}
      accept={".csv"}
    ></input>
    <Grid item container xs={12} md={onlyAdd ? 12 : 6} spacing={2}>
      {!onlyAdd ? <Grid item xs={12}>
        <Typography style={{fontSize: 42, color: "grey", opacity:"0.8", fontWeight: "bold"}}>Broadcast Message</Typography>
      </Grid> : null}
      <Grid item xs={12}>
        <TextField 
          fullWidth
          InputProps={{className: classes1.textField, classes: {
            notchedOutline: classes1.textField
          }}}
          InputLabelProps={{
            className: classes1.textField,
          }}
          autoFocus
          variant="standard"
          label="Broadcast Name"
          value={broadcastState?.name}
          onChange={(e) => {
            setBroadcastState({...broadcastState, name: e.target.value})
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete 
          fullWidth
          options={templateList}
          onChange={(e, value) => {
            setBroadcastState({...broadcastState, templateData: value})
          }}
          value={broadcastState?.templateData ? broadcastState?.templateData : null}
          helperText="Select Whatsapp Templates"
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params}
          InputProps={{...params.InputProps, className: classes1.textField, classes: {
            notchedOutline: classes1.textField
          }}}
          InputLabelProps={{
            className: classes1.textField,
          }}
          label="Select Whatsapp Templates" helperText="Select from your approved whatsapp templates" variant="outlined" />}

        />
      </Grid> 
      <Grid item container alignItems="center" xs={12}>
        <Grid item xs={9}>
          <Typography variant="body1">
            Upload CSV File
          </Typography>
          <Typography variant="caption">
            Download Sample CSV File from {" "}
            <span
              onClick={() => [
                window.open(
                  "https://storage.googleapis.com/website-paperplane/Bulk_Upload_Sample.csv"
                ),
              ]}
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
              }}
            >
              here
            </span>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="outlined"
            style={{borderWidth: 2, fontWeight: "bold"}}
            startIcon={<Add />}
            color="primary"
            fullWidth
            onClick={() => {
              documentsRef.current.click()
            }}
          >
            Add File
          </Button>
          {state?.map((file, idx) => {
            return (
              <div className="m-1">
                <Chip
                  variant="outlined"
                  color="primary"
                  onDelete={() => {
                    const temp = [...state]

                    temp.splice(idx, 1)
                    console.log(temp)
                    setState([...temp])
                    setBroadcastState({...broadcastState, document: temp?.[0] ? {...temp?.[0]} : null})
                  }}
                  label={file.name}
                ></Chip>
              </div>
            )
          })}
        </Grid>
      </Grid> 
      <Grid item xs={12}></Grid>  
      <Grid item xs={12}>
        <FormControlLabel
            control={
              <Switch name="advance options" checked={advanceOption} style={{marginRight: "5px"}} color="primary" />
            }
            onChange={(e) => {
              setAdvanceOptions(e.target.checked)
              if(!e.target.checked){
                setBroadcastState({...broadcastState, start: null, tags: []})
              }
            }}
            label={
              <Typography variant={"h6"} >
                Advance Options
              </Typography>
            }
          />
      </Grid>
      {advanceOption && (<React.Fragment>
        <Grid item xs={12}>
          <DateTimePicker 
            variant="inline"
            label="Schedule Broadcast"
            inputVariant="outlined"
            placeholder={`eg., January 24th 10:00 p.m.`}
            fullWidth
            style={{border: "#AAB7BB"}}
            InputLabelProps={{
              className: classes1.textField,
            }}
            disablePast
            value={broadcastState?.start ? moment(broadcastState?.start).subtract(330, 'm') : null}
            onChange={(e) => {
              setBroadcastState({...broadcastState, start: moment.utc(new Date(e)).add(330, 'm').toISOString()})
            }}
            InputProps={{
              startAdornment: (<InputAdornment position='start'><DateRange color="primary"/></InputAdornment>),
              className: classes1.textField, classes: {
                notchedOutline: classes1.textField
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            onChange={(event, newValue) => {
              // if (typeof newValue === 'string') {
              //   setValue(newValue);
              // } else if (newValue && newValue.inputValue) {
              //   // Create a new value from the user input
              //   setValue(newValue.inputValue);
              //   // setTagOptions([...tagOptions, newValue.inputValue]);

              // } else {
              //   setValue(newValue);
              // }
              setBroadcastState({...broadcastState, tags: [...newValue].map((tag) => tag.inputValue ? tag.inputValue : tag)})
            }}
            value={broadcastState?.tags ? broadcastState?.tags : []}
            multiple
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            
            clearOnBlur
            handleHomeEndKeys
            options={tagOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.title;
            }}
            renderOption={(option) => {
              if (typeof option === 'string') {
                  return option;
              } else {
                  return option.title;
              }
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} 
              InputProps={{...params.InputProps, className: classes1.textField, classes: {
                notchedOutline: classes1.textField
              }}}
              InputLabelProps={{
                className: classes1.textField,
              }}
              label="Select a tag or Create new tag" variant="outlined" />
            )}
          />
        </Grid>
        <Grid container item xs={12} spacing={1} alignItems="center">
          <Grid item xs={8}>
            <Typography>
              Skip contacts who was already broadcasted?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth
              InputProps={{className: classes1.textField, classes: {
                notchedOutline: classes1.textField
              }}}
              InputLabelProps={{
                className: classes1.textField,
              }}
              autoFocus
              variant="standard"
              placeholder="E.g. 2 (skips contact who already got message in last 2 days)"
              label="No. of Past Days"
              value={broadcastState?.skipDays}
              onChange={(e) => {
                setBroadcastState({...broadcastState, skipDays: e.target.value})
              }}
            />
          </Grid>
        </Grid>
        </React.Fragment>
      )}
      <Grid item xs={8}>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          // startIcon={<Add />}
          color="primary"
          onClick={() => createBroadcast()}
          fullWidth
        >
          Create Broadcast
        </Button>
      </Grid>
    </Grid>
    </React.Fragment>
  )
}

export default withRouter(AddNewBroadcast)
