import { Card, CardContent, CardHeader, CircularProgress, Divider, Grid, LinearProgress, Paper, Typography, makeStyles, styled, withStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { CustomizedBarChartNew, CustomizedProductChart } from "./customizeBarChart";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import CountUp from "react-countup"
import { AnalyticTableCard, AnalyticTableCardCreator, colors } from "./Analytics";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { Skeleton } from "@material-ui/lab";



const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#1c1c1c",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FF8042',
    },
    
  }))(LinearProgress);


const useStyles = makeStyles({
// root: {
//     display: 'none', // Hide the background bar
// },
    cardBody: {
        borderRadius: "10px",
        marginTop: "20px",
        background: "#1c1c1c",
        height: "400px",
        border: "1px solid rgba(179, 182, 183, 0.2)"
    }
});

const CustomGrid = styled(Grid)({
    // Set a fixed height for the container
    // height: 300,
    // Enable scrolling
    overflowY: 'scroll',
    overflowX: "hidden",
    maxHeight: "340px",
    height: "340px",
    // Hide the scrollbar
    // scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 1, // Adjust to desired thickness
      backgroundColor: "transparent",
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#1c1c1c', // Adjust to desired color
      position: "absolute"
    },
  });


const AnalyticMetricCard = ({
    data,
    isLoading = false,
    labelKey = "label",
    valueKey = "value",
    totalValue = 100,
    xHeader = "",
    yHeader = "",
    graphType = "horizontalBar",
    graphData = [],
    addOnData = {},
    contents = ["Content 1", "Content 2", "Content 3"],
    xAxisSkipNo = 3,
    differentColor = false,
    cardName = "Median session length",
    hoverCardName = "Overall",
    chartDataKey = "",
    lineChartType = "",
    addOnProgressLabel = '',
    xSize = 2,
    ySize = 9,
    hoverWidth = "60%"
}) => {

    const classes = useStyles();

    const getGraph = () => {
        switch(graphType) {
            case "horizontalBar": {

                return (
                    <CustomizedBarChartNew 
                        xHeader={xHeader} 
                        yHeader={yHeader}
                        xSize={xSize}
                        ySize={ySize}
                        hoverWidth={hoverWidth}
                        data={graphData} addOnProgressLabel={addOnProgressLabel} differentColor={differentColor} cardHeader={hoverCardName} totalValue={totalValue}/>
                )
            }

            case "horizontalBar1": {

                return (
                    <React.Fragment>
                        <Grid container>
                            <Grid item container xs={3}>
                                <Grid item xs={12}>
                                    <Typography style={{marginBottom: "10px"}}>
                                        {addOnData['heading']}
                                    </Typography>
                                    {addOnData['component']}
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                <CustomizedBarChartNew 
                                    xHeader={xHeader} 
                                    totalValue={totalValue}
                                    hoverWidth={hoverWidth}
                                    yHeader={yHeader}
                                    xSize={xSize}
                                    ySize={ySize}
                                    data={graphData} differentColor={differentColor} cardHeader={hoverCardName}/>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
            }

            case "lineChart": {

                return (
                    <AnalyticLineChart data={graphData} xAxisSkipNo={xAxisSkipNo} dataKey={chartDataKey} lineChartType={lineChartType}/>
                )
            }

            case "pieChart": {

                return (
                    <AnalyticPieChart data={graphData} dataKey={cardName} />
                )
            }

            case "numChart": {

                return (
                    <AnalyticNumChart data={addOnData} />
                )
            }

            case "tableChart": {

                return (
                    <AnalyticTableCard data={addOnData} />
                )
            }

            case "tableChartCreator": {

                return (
                    <AnalyticTableCardCreator data={addOnData} />
                )
            }

            case "productChart": {

                return (
                    <CustomizedProductChart 
                        xHeader={xHeader} 
                        yHeader={yHeader}
                        xSize={xSize}
                        ySize={ySize}
                        hoverWidth={hoverWidth}
                        data={graphData} addOnProgressLabel={addOnProgressLabel} differentColor={differentColor} cardHeader={hoverCardName} totalValue={totalValue}/>       
                )
            }

            default: {
                return (
                    null
                )
            }
        }
    }


    const getIcon = () => {
        switch(graphType) {
            case "horizontalBar": {

                return (
                   <i className="fa fa-solid fa-chart-simple" style={{color: "#7856FF", marginRight: "5px"}}></i>
                )
            }

            case "productChart": {

                return (
                   <i className="fa fa-solid fa-chart-simple" style={{color: "#7856FF", marginRight: "5px"}}></i>
                )
            }

            case "horizontalBar1": {

                return (
                   <i className="fa fa-regular fa-chart-bar" style={{color: "#7856FF", marginRight: "5px"}}></i>
                )
            }

            case "tableChart": {

                return (
                   <i className="fa fa-regular fa-chart-bar" style={{color: "#7856FF", marginRight: "5px"}}></i>
                )
            }

            case "tableChartCreator": {

                return (
                   <i className="fa fa-regular fa-chart-bar" style={{color: "#7856FF", marginRight: "5px"}}></i>
                )
            }

            case "lineChart": {

                return (
                    <i className="fa fa-solid fa-chart-line" style={{color: "#FF7557", marginRight: "5px"}}></i>
                )
            }

            case "pieChart": {

                return (
                    <i className="fa fa-solid fa-chart-pie" style={{color: "#7856FF", marginRight: "5px"}}></i>
                )
            }

            case "numChart": {

                return (
                    <i className="fa fa-solid fa-magnifying-glass-chart" style={{color: "#7856FF", marginRight: "5px"}}></i>
                )
            }

            default: {
                return (
                    null
                )
            }
        }
    } 


    return (
        <React.Fragment>
            <Card className={classes.cardBody}>
                <CardContent style={{padding: 0}}>
                    <Grid container alignItems="center">
                        <Grid item xs={data?.headerFilter ? 6 : 12} container style={{paddingLeft: 20, paddingTop: 10}}>
                            <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                                {getIcon()}
                                <Typography style={{fontSize: "16px", fontWeight: "bold", color: "white"}}>
                                    {cardName}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} container alignItems="center" spacing={1} style={{marginBottom: 0}}>
                                {contents?.map((v, i) => (
                                    <React.Fragment>
                                        {i > 0 ? <Grid item><Typography style={{opacity: "0.7"}}>.</Typography></Grid> : null}
                                        <Grid item>
                                            <Typography style={{opacity: "0.7"}}>
                                                {v}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Grid>

                        {data?.headerFilter ? (
                            <Grid item xs={6} container justifyContent="flex-end" style={{paddingLeft: 20, paddingTop: 10, float: "right"}}>
                                <Grid item>
                                    {data?.headerFilterComponent}
                                </Grid>
                            </Grid>
                        ) : null}

                        <Grid item xs={12}>
                            <Divider style={{background: "#B3B6B7", opacity: "0.2" }} />
                        </Grid>

                        {!isLoading ? <CustomGrid item xs={12} container justifyContent="center">
                                    {data?.leftComponent ?? null}
                                    {!data?.hideMain ? <Grid item xs={data?.xs ?? null} style={{padding: 20}}>
                                        {getGraph()}
                                    </Grid> : null}
                                    {data?.rightComponent ?? null}
                                </CustomGrid> : (
                            // <Skeleton variant="rect" animation="pulse" width={"100%"} height={"500px"}/>
                            <CustomGrid item xs={12} container justifyContent="center" alignItems="center">
                                <CircularProgress/>
                            </CustomGrid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    )

}


export default AnalyticMetricCard;



const AnalyticLineChart = ({ data, xAxisSkipNo = 3, dataKey = "uv", lineChartType }) => {


    const CustomXAxisTick = (props) => {
        const { x, y, payload, xSkipNo } = props;
      
        // Decide which ticks to hide based on some condition (e.g., index)
        if (payload.index % 2 === 0) {
          return null; // Hide the tick
        }
      
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" >
              {payload.value}
            </text>
          </g>
        );
      };


      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload?.length) {

            let pData = payload?.[0];

          return (
            <Paper style={{zIndex: 99, paddingTop: 5, paddingBottom: 5, background: "#1c1c1c", borderRadius: "10px",
                border: "1px solid rgba(179, 182, 183, 0.2)",
                width: "60%"
            }}>
                <Grid container>
                    <Grid item xs={1}>
                        <Divider orientation="vertical" style={{
                            background: pData?.color,
                                marginLeft: "8px", width: "5px", borderRadius: "5px"}}/>
                    </Grid>
                    <Grid item container xs={11}>

                        <Grid item xs={12}>
                            <Typography style={{color: "white", fontWeight: "bold", fontSize: "14px"}}>
                                {dataKey}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography style={{ fontSize: "12px"}}>
                                {pData?.payload?.name}{!lineChartType ? ", 2023" : ""}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>
                                <span style={{color: "white", background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                {pData?.payload?.[dataKey]}
                                </span> {pData?.payload?.unit}
                            </Typography>
                        </Grid>

                        {pData?.payload?.percentageChange ? <Grid item xs={12}>
                            <Typography>
                                <span style={{color: pData?.payload?.isIncrease ? "#17A589" : "#EC7063", background: "#252525",  letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                 {pData?.payload?.isIncrease ? "+" : ''}{pData?.payload?.percentageChange}%
                                </span> from previous day
                            </Typography>
                        </Grid> : null}
                    </Grid>

                </Grid>
            </Paper>
          );
        }
      
        return null;
      };

      const CustomLegend = () => (
        <div style={{ display: 'flex', alignItems: 'center', color: 'white', marginLeft: "35%", marginBottom: "5px"}}>
          <div style={{ marginRight: '8px', height: "13px", width: "13px", background: "#00FF38", borderRadius: "3px"  }}></div>
          <span style={{fontSize: "15px"}}>{dataKey}</span>
        </div>
      );


    return (
        <React.Fragment>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
                <LineChart
                width={700}
                height={300}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid horizontal={true} vertical={false} stroke={"rgba(179, 182, 183, 0.2)"} />
                <XAxis dataKey="name" tickLine={false} interval={2} tick={<CustomXAxisTick xSkipNo={xAxisSkipNo}/>}/>
                <YAxis tickLine={false} axisLine={false}/>
                <Tooltip content={<CustomTooltip />} />
                <Legend align="center" verticalAlign="top" height={36} content={<CustomLegend />} />
                {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                <Line dataKey={dataKey} stroke="#00FF38" strokeWidth={2} dot={false} type={lineChartType ?? null}/>
                </LineChart>
            {/* </ResponsiveContainer> */}
        </React.Fragment>
    )
}


const AnalyticPieChart = ({ data, dataKey = "uv" }) => {


      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload?.length) {

            let pData = payload?.[0];

          return (
            <Paper style={{zIndex: 99, paddingTop: 5, paddingBottom: 5, background: "#1c1c1c", borderRadius: "10px",
                border: "1px solid rgba(179, 182, 183, 0.2)",
                // width: "60%"
            }}>
                <Grid container>
                    <Grid item xs={1}>
                        <Divider orientation="vertical" style={{
                            background: pData?.payload?.stroke,
                                marginLeft: "5px", marginRight: "5px", width: "5px", borderRadius: "5px"}}/>
                    </Grid>
                    <Grid item container xs={11}>

                        <Grid item xs={12}>
                            <Typography style={{color: "white", fontWeight: "bold", fontSize: "14px"}}>
                                {dataKey}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography style={{ fontSize: "12px"}}>
                                {pData?.payload?.label}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>
                                <span style={{color: "white", background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                    {pData?.payload?.value}
                                </span> users
                            </Typography>
                        </Grid>

                        {pData?.payload?.numValue ? <Grid item xs={12}>
                            <Typography>
                                {pData?.payload?.numValue}
                            </Typography>
                        </Grid> : null}
                    </Grid>

                </Grid>
            </Paper>
          );
        }
      
        return null;
      };

      const renderLegend = (value, entry) => {

        return (
          <span style={{fontSize: "12px"}}>
            {entry?.payload?.label}
          </span>
        );
      }

      const CustomLegend = ({...props}) => {
        const { payload } = props;
        
        return (
        <div style={{ display: 'flex', alignItems: 'center', color: 'white', marginBottom: "5px"}}>
          {payload?.map(v => (
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{ marginRight: '5px', height: "13px", width: "13px", background: v?.color, borderRadius: "3px"  }}></div>
                <Typography style={{fontSize: "15px", marginRight: "15px", color: "white"}}>{v?.payload?.label}</Typography>
            </div>
            ))}
        </div>
      )};

    return (
        <React.Fragment>
            <PieChart width={800} height={320} margin={{top: 0, left: 200}}>
                <Legend align="left" verticalAlign="top" height={36} 
                    content={CustomLegend}
                />
                <Tooltip content={<CustomTooltip />} />
                <Pie
                    data={data}
                    cx={160}
                    cy={120}
                    innerRadius={80}
                    outerRadius={110}
                    fill="#8884d8"
                    paddingAngle={5}
                    // dataKey="value"
                    // label="label"
                    legendType="line"
                    // labelLine={false}
                >
                    {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} stroke={colors[index % colors.length]}/>
                    ))}
                </Pie>
            </PieChart>
        </React.Fragment>
    )
}


const AnalyticNumChart = ({data}) => {

    const [open, setOpen] = useState(false)

    const handlePopoverOpen = () => {
        setOpen(true)
      };
    
      const handlePopoverClose = () => {
        setOpen(false)
      };

    return (
        <React.Fragment>
            <Grid container alignItems="center" justifyContent="center" style={{marginTop: "15%", marginLeft: "15%", position: "relative",
                background: open ? "#252525" : null, borderRadius:  "10px", width: "70%", zIndex: 9
                }}
                onMouseMove={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                onMouseOut={handlePopoverClose}
            >
                <Grid item container justifyContent="center">
                    <Grid item style={{display: "flex", alignItems: "center"}}>
                        <div style={{height: "10px", width: "10px", borderRadius: "2px", background: "#7856FF", marginRight: "5px"}}></div>

                        <Typography variant="h6" style={{color: "white"}}>
                            {data['heading']}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid item>
                        <Typography
                        // color="primary"
                        style={{
                            fontSize: "58px", fontWeight: "bold", color: "white"
                        }}
                        >
                            <CountUp
                            end={data['number']}
                            suffix=""
                            separator=","
                            delay={2}
                            useEasing={true}
                            className="counterSpan"
                            ></CountUp>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid item>
                        <Typography style={{opacity: "0.7"}}>
                        {data['unit']}
                        </Typography>
                    </Grid>
                </Grid>

                {open ? <Paper style={{position: "absolute", top: "-10%", left: `30%`, paddingTop: 5, paddingBottom: 5, background: "#1c1c1c", borderRadius: "10px",
                    border: "1px solid rgba(179, 182, 183, 0.2)",
                    width: "100%"
                    }}>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <Divider orientation="vertical" style={{
                                            background: "#7856FF",
                                            marginLeft: "8px", width: "5px", marginRight: "15px", borderRadius: "5px"}}/>
                                    </Grid>
                                    <Grid item container xs={11}>

                                        <Grid item xs={12}>
                                        <Typography style={{color: "white", fontWeight: "bold", fontSize: "14px"}}>
                                            {data['heading']}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography>
                                                <span style={{color: "white", background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                                    {data['number']}
                                                    </span>  {data['unit']}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Paper> : null}

            </Grid>
        </React.Fragment>
    )
}


export const AnalyticBarChart = ({ data, xAxisSkipNo = 3, dataKeys = ["sent", "delivered", "read"], argumentKey = "broadcast_name" }) => {


    const CustomXAxisTick = (props) => {
        const { x, y, payload, xSkipNo } = props;
      
        // Decide which ticks to hide based on some condition (e.g., index)
        // if (payload.index % 2 === 0) {
        //   return null; // Hide the tick
        // }
      
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" >
              {payload.value}
            </text>
          </g>
        );
      };


      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload?.length) {

            const getUnit = (label) => {
                if(label?.includes("sent")){
                    return "Sent"
                } else if (label?.includes("delivered")) {
                    return "Delivered"
                } else {
                    return "Read"
                }
            }

            let pData = payload?.[0];

          return (
            <Paper style={{zIndex: 99, paddingTop: 5, paddingBottom: 5, background: "#1c1c1c", borderRadius: "10px",
                border: "1px solid rgba(179, 182, 183, 0.2)",
                width: "80%"
            }}>
                <Grid container>
                    <Grid item xs={1}>
                        <Divider orientation="vertical" style={{
                            background: "white",
                                marginLeft: "8px", marginRight: "20px", width: "5px", borderRadius: "5px"}}/>
                    </Grid>
                    <Grid item container xs={11}>

                        <Grid item xs={12}>
                            <Typography style={{color: "white", fontWeight: "bold", fontSize: "14px"}}>
                                {pData?.payload?.[argumentKey]}
                            </Typography>
                        </Grid>

                        {/* <Grid item xs={12}>
                            <Typography style={{ fontSize: "12px"}}>
                                {pData?.payload?.[argumentKey]}
                            </Typography>
                        </Grid> */}

                        {payload?.map(v => (
                        <Grid item xs={12}>
                            <Typography>
                                <span style={{color: v?.color, background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                {v?.value}
                                </span> {getUnit(v?.name)}
                            </Typography>
                        </Grid>
                        ))}

                    </Grid>

                </Grid>
            </Paper>
          );
        }
      
        return null;
      };

      const CustomLegend = ({...props}) => {

        const {payload} = props;
        
        const getLabel = (label) => {
            if(label?.includes("sent")){
                return "Sent"
            } else if (label?.includes("delivered")) {
                return "Delivered"
            } else {
                return "Read"
            }
        }

        return (
        <div style={{ display: 'flex', alignItems: 'center', color: 'white', marginLeft: "35%", marginBottom: "5px"}}>
          {payload?.map(v => (
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{ marginRight: '5px', height: "13px", width: "13px", background: v?.color, borderRadius: "3px"  }}></div>
                <Typography style={{fontSize: "15px", marginRight: "15px", color: "white"}}>{getLabel(v?.payload?.dataKey)}</Typography>
            </div>
            ))}
        </div>
      )};


    return (
        <React.Fragment>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
                <BarChart
                width={700}
                height={300}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid horizontal={true} vertical={false} stroke={"rgba(179, 182, 183, 0.2)"} />
                <XAxis dataKey={argumentKey} tickLine={false} tick={<CustomXAxisTick xSkipNo={xAxisSkipNo}/>}/>
                <YAxis tickLine={false} axisLine={false}/>
                <Tooltip  
                    cursor={{fill: "#252525"}}
                    wrapperStyle={{ backgroundColor: 'transparent', border: 'none' }}
                    content={<CustomTooltip />} isAnimationActive={false} />
                <Legend align="center" verticalAlign="top" height={36} content={<CustomLegend />} />
                {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                {dataKeys?.map((v, i) => (
                <Bar dataKey={v} radius={[8, 8, 0, 0]} fill={colors[i % colors.length]} isAnimationActive={false} stroke={colors[i % colors.length]}/>
                ))}
                </BarChart>
            {/* </ResponsiveContainer> */}
        </React.Fragment>
    )
}


const AnalyticProductChart = ({data}) => {

    const [open, setOpen] = useState(false)

    const handlePopoverOpen = () => {
        setOpen(true)
      };
    
      const handlePopoverClose = () => {
        setOpen(false)
      };

    return (
        <React.Fragment>
            <Grid container alignItems="center" justifyContent="center" style={{marginTop: "15%", marginLeft: "15%", position: "relative",
                background: open ? "#252525" : null, borderRadius:  "10px", width: "70%", zIndex: 9
                }}
                onMouseMove={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                onMouseOut={handlePopoverClose}
            >
                <Grid item container justifyContent="center">
                    <Grid item style={{display: "flex", alignItems: "center"}}>
                        <div style={{height: "10px", width: "10px", borderRadius: "2px", background: "#7856FF", marginRight: "5px"}}></div>

                        <Typography variant="h6" style={{color: "white"}}>
                            {data['heading']}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid item>
                        <Typography
                        // color="primary"
                        style={{
                            fontSize: "58px", fontWeight: "bold", color: "white"
                        }}
                        >
                            <CountUp
                            end={data['number']}
                            suffix=""
                            separator=","
                            delay={2}
                            useEasing={true}
                            className="counterSpan"
                            ></CountUp>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid item>
                        <Typography style={{opacity: "0.7"}}>
                        {data['unit']}
                        </Typography>
                    </Grid>
                </Grid>

                {open ? <Paper style={{position: "absolute", top: "-10%", left: `30%`, paddingTop: 5, paddingBottom: 5, background: "#1c1c1c", borderRadius: "10px",
                    border: "1px solid rgba(179, 182, 183, 0.2)",
                    width: "100%"
                    }}>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <Divider orientation="vertical" style={{
                                            background: "#7856FF",
                                            marginLeft: "8px", width: "5px", marginRight: "15px", borderRadius: "5px"}}/>
                                    </Grid>
                                    <Grid item container xs={11}>

                                        <Grid item xs={12}>
                                        <Typography style={{color: "white", fontWeight: "bold", fontSize: "14px"}}>
                                            {data['heading']}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography>
                                                <span style={{color: "white", background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                                    {data['number']}
                                                    </span>  {data['unit']}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Paper> : null}

            </Grid>
        </React.Fragment>
    )
}