import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Checkbox,
  TextField,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Switch,
  IconButton,
  Divider,
  Paper,
} from '@material-ui/core';
import { Close, FilterNone } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

const styles = (theme) => ({
  container: {
    maxWidth: '500px',
    margin: '0 auto',
  },
});

const useStyles = makeStyles((theme) => ({
  textField: {
    color: '#AAB7B8', // Change the color of the placeholder text
    opacity: 1,
    // fontWeight: "bold",
    borderColor: "#AAB7B8 !important"
  },
}))

function WhatsAppTemplateForm({ classes }) {
  const [headerToggle, setHeaderToggle] = useState(false);
  const [headerMedia, setHeaderMedia] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [footerToggle, setFooterToggle] = useState(false);
  const [footerText, setFooterText] = useState('');
  const [buttonActions, setButtonAction] = useState([]);
  const [buttonName, setButtonName] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [buttons, setButtons] = useState([]);

  const classes1 = useStyles()

  const buttonActionOptions = ["Quick Reply", "Visit Website", "Call Phone number"]; // Replace with your specific options.

  return (
    <Grid container spacing={1} className={classes.container}>

      {/* <WhatsappTemplateView headerMedia={headerMedia} bodyText={bodyText} footerText={footerText} buttons={buttons} /> */}

      <Grid item xs={12}>
        <Typography variant="h6">Template Name  </Typography>
        <TextField
        style={{marginTop: 15}}
        InputProps={{className: classes1.textField, classes: {
          notchedOutline: classes1.textField
        }}}
        InputLabelProps={{
          className: classes1.textField,
        }}
        // inputProps={{className: classes1.textField}}
          label="Template Name"
          variant="outlined"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
        <Typography variant="h6">Header Section</Typography>
        <FormControlLabel
          style={{marginLeft: "auto"}}
          control={
            <Switch
              style={{color: "#AAB7BB"}}
                color='primary'
              checked={headerToggle}
              onChange={() => setHeaderToggle(!headerToggle)}
              value="headerToggle"
            />
          }
        />
      </Grid>

      {headerToggle && (
          <input
            type="file"
            accept="image/*, video/*, .pdf"
            onChange={(e) => setHeaderMedia(e.target.files[0])}
          />
        )}

      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Body Section</Typography>
        <TextField
        style={{marginTop: 15}}
        InputProps={{className: classes1.textField, classes: {
          notchedOutline: classes1.textField
        }}}
        InputLabelProps={{
          className: classes1.textField,
        }}
          label="Body Text"
          multiline
          rows={4}
          variant="outlined"
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
        <Typography variant="h6">Footer Section</Typography>
        <FormControlLabel
          style={{marginLeft: "auto"}}
          control={
            <Switch
            style={{color: "#AAB7BB"}}
            color="primary"
              checked={footerToggle}
              onChange={() => setFooterToggle(!footerToggle)}
              value="footerToggle"
            />
          }
        />
      </Grid>
    {footerToggle && (
        <TextField
        InputProps={{className: classes1.textField, classes: {
          notchedOutline: classes1.textField
        }}}
        InputLabelProps={{
          className: classes1.textField,
        }}
        label="Footer Text"
        variant="outlined"
        value={footerText}
        onChange={(e) => setFooterText(e.target.value)}
        fullWidth
        />
    )}

    <Grid item xs={12}></Grid>

      <Grid item container xs={12}>
        <Grid item xs={6}>
            <Typography variant="h6">Buttons</Typography>
            <InputLabel id="button-action-label" style={{color: "#AAB7B8"}}>Select Button Action</InputLabel>
        </Grid>
        <Grid item xs={6}>
            <Autocomplete
            options={buttonActionOptions}
            value={null}
            onChange={(e, val) => {
                let tempBActions = [{action: val, value: ""}, ...buttonActions];
                setButtonAction([...tempBActions]);
            }}
            renderInput={(params) => <TextField {...params} 
            InputProps={{...params?.InputProps, className: classes1.textField, classes: {
              notchedOutline: classes1.textField
            }}}
            InputLabelProps={{
              className: classes1.textField,
            }}
            label="Select Button Action" variant="outlined" fullWidth />}
            />
        </Grid>
      </Grid>
        {buttonActions && buttonActions.length ? buttonActions.map((v, i) => (
          <Grid container spacing={1} alignItems="center" style={{marginTop: '10px'}}>
            <Grid item xs={v?.action === "Quick Reply" ? 11 : 4}>
              <TextField
                label="Button Text"
                variant="outlined"
                InputProps={{className: classes1.textField, classes: {
                  notchedOutline: classes1.textField
                }}}
                InputLabelProps={{
                  className: classes1.textField,
                }}
                // value={buttonName}
                onChange={(e) => setButtonName(e.target.value)}
                fullWidth
              />
            </Grid>
            {v?.action === "Call Phone number" ? <React.Fragment>
                <Grid item xs={3}>
                  <TextField
                    label="Country"
                    InputProps={{className: classes1.textField, classes: {
                      notchedOutline: classes1.textField
                    }}}
                    InputLabelProps={{
                      className: classes1.textField,
                    }}
                    placeholder="For eg. +91"
                    variant="outlined"
                    // value={buttonName}
                    // onChange={(e) => setButtonName(e.target.value)}
                    fullWidth
                  />  
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Phone"
                    InputProps={{className: classes1.textField, classes: {
                      notchedOutline: classes1.textField
                    }}}
                    InputLabelProps={{
                      className: classes1.textField,
                    }}
                    variant="outlined"
                    // value={buttonName}
                    // onChange={(e) => setButtonName(e.target.value)}
                    fullWidth
                  />  
                </Grid>
              </React.Fragment> : null}

              {v?.action === "Visit Website" ? <React.Fragment>
                <Grid item xs={7}>
                  <TextField
                  InputProps={{className: classes1.textField, classes: {
                    notchedOutline: classes1.textField
                  }}}
                  InputLabelProps={{
                    className: classes1.textField,
                  }}
                    label="Website Link"
                    // placeholder="For eg. +91"
                    variant="outlined"
                    // value={buttonName}
                    // onChange={(e) => setButtonName(e.target.value)}
                    fullWidth
                  />  
                </Grid>
              </React.Fragment> : null}

            <Grid item xs={1}>
              <IconButton onClick={() =>{
                let tempBActions = [...buttonActions];
                tempBActions.splice(i, 1);

                setButtonAction([...tempBActions])
              }}>
                <Close style={{color: "#AAB7BB"}} />
              </IconButton>
            </Grid>
          </Grid>
        )): null}
    
        <Grid item xs={12} style={{marginTop: 20}}>
            <Button variant="contained" color="primary">
                Submit    
            </Button> 
        </Grid>
    </Grid>
  );
}

export default withStyles(styles)(WhatsAppTemplateForm);



function WhatsappTemplateView({headerMedia, bodyText, footerText, buttons }) {

  const getHeader = () => {
    return (
        <Grid container justifyContent='center' alignItems='center' style={{height: "60px"}}>
          <Grid item>
            <FilterNone />
          </Grid>
        </Grid>
    )
  }

  const getBody = () => {
    return (
      <Grid container>
        <Grid item>
          {bodyText}
        </Grid>
        <Grid item xs={12}></Grid>
        {footerText ? <Grid item>
          <Typography variant="body2">
            {footerText}
          </Typography>
        </Grid> : null}
      </Grid>
  )
  }

  const getButtons = () => {
    return (
      <Grid container justifyContent='center'>
        {buttons?.map(v => (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item>
              {v?.value}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
  )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} component={Paper}>
        {headerMedia ? getHeader() : null}
        {getBody()}
        {buttons && buttons?.length ? getButtons() : null}
      </Grid>
    </Grid>
  );
}