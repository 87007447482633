import io from "socket.io-client"

const steerSocket =  io(
  // "https://loadbalancer.paperplane.health:8040/steer-socket",
   {
  transports: ["websocket", "polling"]
})

// const steerSocket =  io(
//   "localhost:8000/chat-message", {
//   transports: ["websocket", "polling"]
// })

// const steerSocket =  io(
//   "https://wmapi.paperplane.health:8000/chat-message", {
//   transports: ["websocket", "polling"]
// })

// steerSocket.on("connect", () => {
//   console.log(steerSocket.id, "connect")
// })
// steerSocket.on("disconnect", () => {
//   console.log(steerSocket.id, "disconnect")
// })

export default steerSocket
