import { Divider, Grid, LinearProgress, Paper, Popover, Tooltip, Typography, makeStyles, useTheme, withStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { colors } from "./Analytics";
import { Launch } from "@material-ui/icons";


const createStyledProgress = (colorPrimary = "#1c1c1c", barColor = "#FF8042") => {
    return withStyles((theme) => ({
      root: {
        height: 18,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: colorPrimary,
      },
      bar: {
        borderRadius: 5,
        backgroundColor: barColor,
      },
    }))(LinearProgress);
  };


const useStyles = makeStyles({
root: {
    display: 'none', // Hide the background bar
},
});


const CustomizedBarChart = ({
    data,
    labelKey = "label",
    valueKey = "value",
    totalValue = 100
}) => {

    const [progress, setProgress] = useState(Array(data.length).fill(0))
    const classes = useStyles();

    useEffect(() => {
        if(data && data?.length){
            const interval = setInterval(() => {
            // Increment the progress for each loading value
            const newProgress = data.map((value, index) =>
                progress < value[valueKey] ? progress + 1 : value[valueKey]
            );
    
            setProgress(newProgress);
    
            // Check if all loaders have reached their loading values
            if (newProgress.every((value) => value === totalValue)) {
                clearInterval(interval);
            }
            }, 150); // Adjust the interval for smoother animation
    
            return () => {
            clearInterval(interval);
            };
        }
    }, [progress, data, totalValue]);

    const calculatePercentage = (value, totalValue) => {
        return (value / totalValue) * 100;
    };

    const BorderLinearProgress = createStyledProgress();

    return (
        <React.Fragment>
            <Grid container spacing={2} style={{marginTop: "5px"}}>
                {data?.map((val, idx) => (
                    <Grid item container xs={12}>
                        <Grid item xs={10} style={{display: "flex", alignItems: "center"}}>
                            <Typography style={{fontSize: "16px", opacity: 0.7}}>
                                {val?.[labelKey]}
                            </Typography>

                            <Typography style={{fontSize: "15px", opacity: 0.7, marginLeft: 'auto'}}>
                                {calculatePercentage(val?.[valueKey], totalValue)}%
                            </Typography>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={7} style={{marginTop: "5px", opacity: "0.7", position: "relative"}}> 
                            <BorderLinearProgress
                                key={idx}
                                variant="determinate"
                                value={calculatePercentage(progress?.[idx], totalValue)}
                                color="primary"
                                />

                            <Typography style={{position: "absolute", top: 10, left: `${calculatePercentage(progress?.[idx], totalValue)+1}%`}}>
                                {progress?.[idx]}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )

}



export const CustomizedBarChartNew = ({
    data,
    labelKey = "label",
    valueKey = "yValue",
    totalValue = 100,
    headerAllow = true,
    xHeader = "",
    yHeader = "",
    addOnProgressLabel = "",
    cardHeader = "Overall",
    differentColor = false,
    xSize = 2,
    ySize = 9,
    hoverWidth = "60%"
}) => {

    const [progress, setProgress] = useState(Array(data.length).fill(0))
    const classes = useStyles();

    useEffect(() => {
        if(data && data?.length){
            setProgress(Array(data.length).fill(0))
        }
    }, [data])

    useEffect(() => {
        if(data && data?.length){
            const interval = setInterval(() => {
                let tempDiff = Number(totalValue / 5) > 5 ? (Number(totalValue / 5)) : 5
            // Increment the progress for each loading value
            const newProgress = data.map((value, index) =>
                (progress[index] + (tempDiff)) < value[valueKey] ? progress[index] + tempDiff : value[valueKey]
                // value[valueKey]
            );
    
            setProgress(newProgress);
    
            // Check if all loaders have reached their loading values
            if (newProgress.every((value) => value === totalValue)) {
                clearInterval(interval);
            }
            },150); // Adjust the interval for smoother animation
    
            return () => {
            clearInterval(interval);
            };
        }
    }, [progress, data, totalValue]);

    const calculatePercentage = (value, totalValue) => {
        return (value / totalValue) * 100;
    };

    const theme = useTheme()

    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false)
    // const [popoverData, setPopoverData] = React.useState(null)

    // const handlePopoverOpen = (event, data) => {
    //     console.log("mouse enter")

    //   setAnchorEl(event.currentTarget);
    //   setOpen(true);
    //   setPopoverData(data)
    // };
  
    // const handlePopoverClose = () => {
    //     console.log("mouseleave")
    //   setAnchorEl(null);
    //   setOpen(false);
    //   setPopoverData(null)
    // };



    const [anchorPosition, setAnchorPosition] = useState({ mouseX: null, mouseY: null });

    const handlePopoverOpen = (index) => {
      setOpen(index)
    };
  
    const handlePopoverClose = () => {
      setOpen(false)
    };
  
  
  
    
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                
                {headerAllow ? 
                    <Grid item xs={12} container alignItems="center" style={{marginBottom: "8px"}}>
                        <Grid item xs={xSize}>
                            <Typography>
                                {xHeader}
                            </Typography>
                        </Grid>

                        <Grid item xs={ySize} style={{textAlign: "center"}}>
                            <Typography>
                                {yHeader}
                            </Typography>
                        </Grid>
                    </Grid>
                : null}

                {data?.map((val, idx) => {
                    let BorderLinearProgress = null
                    if (differentColor) {
                        BorderLinearProgress = createStyledProgress("#1c1c1c", colors[idx % colors.length])
                    } else {
                        BorderLinearProgress = createStyledProgress("#1c1c1c", "#7856FF")
                    }

                    return (
                    <Grid item container xs={12} style={{position: "relative", background: open === idx ? "#252525" : null, borderRadius:  "5px"}} 
                        onMouseMove={(e) => handlePopoverOpen(idx)}
                        onMouseLeave={handlePopoverClose}
                        onMouseOut={handlePopoverClose}
                        alignItems="center"
                            >
                        <Grid item xs={xSize}>
                            <Typography style={{maxWidth: "180px",overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textOverflow: "ellipsis"}}>
                                {val?.xValue}
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={ySize} style={{opacity: "0.9", position: "relative"}}> 
                            <BorderLinearProgress
                                key={idx}
                                variant="determinate"
                                value={calculatePercentage(progress?.[idx], totalValue)}
                                color="primary"
                                />

                            <Typography style={{position: "absolute", bottom: -2, fontSize: "12px", letterSpacing: "2px", left: `${calculatePercentage(progress?.[idx], totalValue)+1}%`, color: "white", fontWeight: "bold",
                                    fontFamily: "Apercu Pro"
                                }}>
                                {progress?.[idx]}{addOnProgressLabel}
                            </Typography>
                        </Grid>

                        {open === idx ? <Paper style={{position: "absolute", top: -60, left: `30%`, zIndex: 99, paddingTop: 5, paddingBottom: 5, background: "#1c1c1c", borderRadius: "10px",
                            border: "1px solid rgba(179, 182, 183, 0.2)",
                            width: hoverWidth
                            }}>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <Divider orientation="vertical" style={{
                                            background: differentColor ? colors[idx % colors.length] : "#7856FF",
                                            marginLeft: "8px", marginRight: "5px", width: "5px", borderRadius: "5px"}}/>
                                    </Grid>
                                    <Grid item container xs={11}>

                                        <Grid item xs={12}>
                                            <Typography style={{color: "white", fontWeight: "bold", fontSize: "14px"}}>
                                                {cardHeader ? cardHeader : val?.xValue}
                                            </Typography>
                                        </Grid>

                                        {cardHeader ? <Grid item xs={12}>
                                            <Typography style={{ fontSize: "12px"}}>
                                                {val?.xValue}
                                            </Typography>
                                        </Grid> : null}

                                        <Grid item xs={12}>
                                            <Typography>
                                                <span style={{color: "white", background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                                {val?.yValue}{val?.isPercent ?"%"  : ""}
                                                </span> {val?.isPercent ? "of conversions" : ""} {val?.unitValue ?? ""}
                                            </Typography>
                                        </Grid>

                                        {val?.overallPercent ? <Grid item xs={12}>
                                            <Typography>
                                                <span style={{color: "white", background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                                {val?.overallPercent}%
                                                </span> of overall
                                            </Typography>
                                        </Grid> : null}
                                    </Grid>

                                </Grid>
                            </Paper> : null}
                    </Grid>
                )})}
            </Grid>
            {/* {open ? <Popover
                 id="mouse-over-popover"
                //  className={classes.popover}
                //  classes={{
                //    paper: classes.paper,
                //  }}
                 open={open}
                 anchorEl={anchorEl}
                 anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'left',
                 }}
                 transformOrigin={{
                   vertical: 'top',
                   horizontal: 'left',
                 }}
                 onClose={handlePopoverClose}
                 disableRestoreFocus
            >
                
            </Popover> : null} */}


        </React.Fragment>
    )

}


export const CustomizedProductChart = ({
    data,
    labelKey = "label",
    valueKey = "yValue",
    totalValue = 100,
    headerAllow = true,
    xHeader = "",
    yHeader = "",
    addOnProgressLabel = "",
    cardHeader = "Overall",
    differentColor = false,
    xSize = 2,
    ySize = 9,
    hoverWidth = "60%"
}) => {

    const [progress, setProgress] = useState(Array(data.length).fill(0))
    const classes = useStyles();

    useEffect(() => {
        if(data && data?.length){
            setProgress(Array(data.length).fill(0))
        }
    }, [data])

    useEffect(() => {
        if(data && data?.length){
            const interval = setInterval(() => {
                let tempDiff = Number(totalValue / 5) > 5 ? (Number(totalValue / 5)) : 5
            // Increment the progress for each loading value
            const newProgress = data.map((value, index) =>
                (progress[index] + (tempDiff)) < value[valueKey] ? progress[index] + tempDiff : value[valueKey]
                // value[valueKey]
            );
    
            setProgress(newProgress);
    
            // Check if all loaders have reached their loading values
            if (newProgress.every((value) => value === totalValue)) {
                clearInterval(interval);
            }
            },150); // Adjust the interval for smoother animation
    
            return () => {
            clearInterval(interval);
            };
        }
    }, [progress, data, totalValue]);

    const calculatePercentage = (value, totalValue) => {
        return (value / totalValue) * 100;
    };

    const theme = useTheme()

    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false)
    // const [popoverData, setPopoverData] = React.useState(null)

    // const handlePopoverOpen = (event, data) => {
    //     console.log("mouse enter")

    //   setAnchorEl(event.currentTarget);
    //   setOpen(true);
    //   setPopoverData(data)
    // };
  
    // const handlePopoverClose = () => {
    //     console.log("mouseleave")
    //   setAnchorEl(null);
    //   setOpen(false);
    //   setPopoverData(null)
    // };



    const [anchorPosition, setAnchorPosition] = useState({ mouseX: null, mouseY: null });

    const handlePopoverOpen = (index) => {
      setOpen(index)
    };
  
    const handlePopoverClose = () => {
      setOpen(false)
    };
  
  
  
    
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                
                {headerAllow ? 
                    <Grid item xs={12} container alignItems="center" style={{marginBottom: "8px"}}>
                        <Grid item xs={xSize}>
                            <Typography>
                                {xHeader}
                            </Typography>
                        </Grid>

                        <Grid item xs={ySize} style={{textAlign: "center"}}>
                            <Typography>
                                {yHeader}
                            </Typography>
                        </Grid>
                    </Grid>
                : null}

                {data?.length ? data?.map((val, idx) => {
                    let BorderLinearProgress = null
                    if (differentColor) {
                        BorderLinearProgress = createStyledProgress("#1c1c1c", colors[idx % colors.length])
                    } else {
                        BorderLinearProgress = createStyledProgress("#1c1c1c", "#7856FF")
                    }

                    return (
                    <Grid item container spacing={1} xs={12} style={{position: "relative", background: open === idx ? "#252525" : null, borderRadius:  "5px"}} 
                        onMouseMove={(e) => handlePopoverOpen(idx)}
                        onMouseLeave={handlePopoverClose}
                        onMouseOut={handlePopoverClose}
                        alignItems="center"
                            >
                        <Grid item container spacing={2} xs={12} alignItems="center">
                            <Grid item xs={1}>
                                <img src={val?.image} style={{width: "105%", borderRadius: "10%"}}></img>
                            </Grid>
                            <Grid item xs={11} container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={{maxWidth: "180px",overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        cursor: "pointer",
                                        alignItems: "center",
                                        textOverflow: "ellipsis"}} onClick={() => window.open(val?.productLink)}>
                                        {val?.xValue} <Launch style={{fontSize: "16px", marginLeft: "5px"}}/>
                                    </Typography>
                                </Grid>
                                
                                {/* <Grid item xs={12}></Grid> */}
                                <Grid item xs={12} style={{opacity: "0.9", position: "relative"}}> 
                                    <BorderLinearProgress
                                        key={idx}
                                        variant="determinate"
                                        value={calculatePercentage(progress?.[idx], totalValue)}
                                        color="primary"
                                        />

                                    <Typography style={{position: "absolute", bottom: -2, fontSize: "12px", letterSpacing: "2px", left: `${calculatePercentage(progress?.[idx], totalValue)+1}%`, color: "white", fontWeight: "bold",
                                            fontFamily: "Apercu Pro"
                                        }}>
                                        {progress?.[idx]}{addOnProgressLabel}
                                    </Typography>
                                </Grid>
                                {/* <Typography variant="subtitle1" style={{maxWidth: "auto",overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    textOverflow: "ellipsis", color: '#3498DB'}}>
                                    {val?.productLink}
                                </Typography> */}
                            </Grid>
                        </Grid>
                        
                       

                        {open === idx ? <Paper style={{position: "absolute", top: -60, left: `30%`, zIndex: 99, paddingTop: 5, paddingBottom: 5, background: "#1c1c1c", borderRadius: "10px",
                            border: "1px solid rgba(179, 182, 183, 0.2)",
                            width: hoverWidth
                            }}>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <Divider orientation="vertical" style={{
                                            background: differentColor ? colors[idx % colors.length] : "#7856FF",
                                            marginLeft: "8px", marginRight: "5px", width: "5px", borderRadius: "5px"}}/>
                                    </Grid>
                                    <Grid item container xs={11}>

                                        <Grid item xs={12}>
                                            <Typography style={{color: "white", fontWeight: "bold", fontSize: "14px"}}>
                                                {cardHeader ? cardHeader : val?.xValue}
                                            </Typography>
                                        </Grid>

                                        {cardHeader ? <Grid item xs={12}>
                                            <Typography style={{ fontSize: "12px"}}>
                                                {val?.xValue}
                                            </Typography>
                                        </Grid> : null}

                                        <Grid item xs={12}>
                                            <Typography>
                                                <span style={{color: "white", background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                                {val?.yValue}{val?.isPercent ?"%"  : ""}
                                                </span> {val?.isPercent ? "of conversions" : ""} {val?.unitValue ?? ""}
                                            </Typography>
                                        </Grid>

                                        {val?.overallPercent ? <Grid item xs={12}>
                                            <Typography>
                                                <span style={{color: "white", background: "#252525", letterSpacing: "2px", padding: "2px", borderRadius: "2px", fontSize: "13px"}}>
                                                {val?.overallPercent}%
                                                </span> of overall
                                            </Typography>
                                        </Grid> : null}
                                    </Grid>

                                </Grid>
                            </Paper> : null}
                    </Grid>
                )}) : <Grid container>
                    <Grid item style={{marginLeft: "20px"}}>
                        <Typography>
                            No Data Found
                        </Typography>
                    </Grid>
                    </Grid>}
            </Grid>
            {/* {open ? <Popover
                 id="mouse-over-popover"
                //  className={classes.popover}
                //  classes={{
                //    paper: classes.paper,
                //  }}
                 open={open}
                 anchorEl={anchorEl}
                 anchorOrigin={{
                   vertical: 'bottom',
                   horizontal: 'left',
                 }}
                 transformOrigin={{
                   vertical: 'top',
                   horizontal: 'left',
                 }}
                 onClose={handlePopoverClose}
                 disableRestoreFocus
            >
                
            </Popover> : null} */}


        </React.Fragment>
    )

}