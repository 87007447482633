
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Button, AppBar, Tabs, Tab, Box, Divider, TextField, IconButton, Avatar, CircularProgress, Paper } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import steerWheel from "../../../assets/img/icons/common/steering-wheel.png"
import loadingAnimation from "../../../assets/animationData/animation_lmku3nyp.json"
import loadingAnimation1 from "../../../assets/animationData/animation_lnlgbgju.json"

import axios from "axios";
import { Add, BarChart, Chat, ChatBubbleOutline, ChatOutlined, Delete, DeleteOutlineOutlined, Loop, Send, Stop } from '@material-ui/icons';
import GenericAnimation from '../../../components/animation';
import WhatsappTemplateForm from  './waTemplateForm'
import gifLoader from "../../../assets/animationData/sparkle_resting_v2_1ff6f6a71f2d298b1a31.gif"

import {v4} from 'uuid'
import {
  curveCatmullRom,
  area,
} from 'd3-shape';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Legend,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, ArgumentScale, AreaSeries, EventTracker } from '@devexpress/dx-react-chart';
import steerSocket from '../../../socket/steerSocket';
import { AddNewBroadcast1 } from '../../../components/Broadcast/add-new-broadcast';
import { AnalyticBarChart } from '../Analytics/analyticMetricCard';

const PREFIX = 'PPL';

const classes = {
  chart: `${PREFIX}-chart`,
};


const Root = props => (
  <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
);
const Label = props => (
  <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
);


const useStyles = makeStyles((theme) => ({
    root: {
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },


gradientDivider: {
  background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0), #4D63DD, rgba(255, 0, 0, 0))', // Customize the gradient colors here
  width: '1px', // Customize the height of the divider
  margin: '16px 0', // Customize the margin as needed
}, 

gradientDivider1: {
  background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0), #4D63DD, rgba(255, 0, 0, 0))', // Customize the gradient colors here
  height: '1px', // Customize the height of the divider
  margin: '16px 0', // Customize the margin as needed
}, 



focused: {
  borderColor: "#3498DB !important",
},
input: {
  color: 'green', // Change the color of the input text
},
placeholder: {
  color: '#AAB7B8', // Change the color of the placeholder text
  opacity: 1,
  fontWeight: "bold",
  borderColor: "#3498DB"
},

  }));


const CustomGrid = styled(Grid)({
  // Set a fixed height for the container
  // height: "70vh",
  // Enable scrolling
  overflowY: 'scroll',
  // Hide the scrollbar
  // scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 1, // Adjust to desired thickness
    height: 0,
    backgroundColor: "transparent",
    // display: "none"
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#AAB7B8', // Adjust to desired color
    position: "absolute",
  },
});
  


const SteerChat = ({ isMobile }) => {

    const classes = useStyles();

    const [focusedCardIndex, setFocusedCardIndex] = useState(0);
    const cardRefs = useRef([]);

    const [isCardVisible, setIsCardVisible] = useState(Array(cardRefs.current.length).fill(false)); // Initialize the visibility state
    
  
    useEffect(() => {
      setIsCardVisible(Array(cardRefs.current.length).fill(false))
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1, // Adjust this threshold as needed (0.5 means 50% of the card is in view)
      };

  
      const callback = (entries, observer) => {
        entries.forEach((entry, index) => {
          let enteryIndex = cardRefs.current.findIndex(f => f?.id === entry?.target?.id);
          if (entry.isIntersecting) {
            setIsCardVisible((prev) => {
              const updatedVisibility = [...prev];
              updatedVisibility[enteryIndex] = true;
              return updatedVisibility;
            });
          } else {
            setIsCardVisible((prev) => {
              const updatedVisibility = [...prev];
              updatedVisibility[enteryIndex] = false;
              return updatedVisibility;
            });
          }
        });
      };
  
      const observers = cardRefs.current.map((cardRef, index) => {
        const observer = new IntersectionObserver(callback, options);
        observer.observe(cardRef);
        return observer;
      });
  
      // Clean up the observers when the component unmounts
      return () => {
        observers.forEach((observer) => {
          observer.disconnect();
        });
      };
    }, [cardRefs]);


  const [chatMessages, setChatMessages] = useState([]) 

  const [apiLoader, setApiLoader] = useState(false)

  const [chatPanelData, setChatPanelData] = useState([])
  const [selectedPanelIndex, setSelectedPanelIndex] = useState(0)

  const [chatId, setChatId] = useState(null)

  const getChatData = () => {
    return [...chatPanelData]
  }

  const getAIMessage = (userChatText) => {
    let tempChatMessages = [...chatMessages];
    
    tempChatMessages.push({
      "from": "user",
      "text": userChatText
    })
    
    setChatMessages([...tempChatMessages]);
    
    let tempChatId = chatId;
    
    let tempChatPanel = [...chatPanelData];
    
    if(!tempChatId){
      tempChatId = v4();
      setChatId(tempChatId);
      
      tempChatPanel = [{
        chatMessages: [...tempChatMessages],
        chatId: tempChatId,
        loader: true
      }, ...tempChatPanel]
      setChatPanelData([...tempChatPanel]);
      setSelectedPanelIndex(0);
    } else {
      tempChatPanel[selectedPanelIndex].chatMessages = [...tempChatMessages];
      tempChatPanel[selectedPanelIndex].loader = true;
      setChatPanelData([...tempChatPanel]);
    }
    
    setApiLoader(tempChatId);
    // api call will be here

    let lowerText = userChatText?.toLowerCase();

    if((lowerText?.includes("approv") || lowerText?.includes("make") || lowerText?.includes("create")) && lowerText?.includes("template")) {

      setTimeout(() => {
        tempChatMessages.push({
          "from": "ai",
          "text": "Please fill in these details to create a whatsapp template. It'll take 24-48 Hours in approval.",
          "waTemplate": true
        })
        setChatMessages([...tempChatMessages]);
    
        tempChatPanel[selectedPanelIndex] = {
          chatMessages: [...tempChatMessages],
          chatId: tempChatId
        };
        setChatPanelData([...tempChatPanel]);
        setApiLoader(null);
      }, 1000)

    } else if((lowerText?.includes("schedule") || lowerText?.includes("make") || lowerText?.includes("create")) && lowerText?.includes("broadcast") && !lowerText?.includes("created") && !lowerText?.includes("scheduled")) {

      setTimeout(() => {
        tempChatMessages.push({
          "from": "ai",
          "text": "Please fill in these details to schedule a broadcast.",
          "broadcastTemplate": true
        })
        setChatMessages([...tempChatMessages]);
    
        tempChatPanel[selectedPanelIndex] = {
          chatMessages: [...tempChatMessages],
          chatId: tempChatId
        };
        setChatPanelData([...tempChatPanel]);
        setApiLoader(null);
      }, 1000)

    } else {

      steerSocket.emit("user_chat", {
        "message": userChatText,
        "chatId": tempChatId
      })

    }

  }


  const stopStreaming = () => {
    steerSocket.emit("stop_steer_stream", {});
  }
  
  // useEffect(() => {

  //   if(chatPanelData) {

  //   }

  // }, [chatPanelData])

  const parseIntoGraph = (chatData, chatIndex) => {

    axios.post(`https://loadbalancer.paperplane.health:8040/api/v1/steer/output-parse`, {
      "message": chatData?.text,
      "otherData": {
        "chatIndex": chatIndex,
        "chatId": chatId,
        "chatMessages": chatMessages
      }
    }).then(res => {
      if (res.status === 200) {
        let tempChatMessages = [...chatMessages];

        tempChatMessages[chatIndex] = {...chatData, graphData: {...res?.data} };

        setChatMessages([...tempChatMessages]);
      }
      setApiLoader(false);
    })
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatMessages])


  const scrollToBottom = (id = "messageContainer") => {
    let objDiv = document.getElementById(id)
    if (objDiv) {
      // objDiv.scrollTo(0, objDiv.scrollHeight + 200)
      // objDiv.scrollIntoView(false)
      // objDiv.scrollIntoView()
      objDiv.scrollIntoView({ behavior: "instant", block: 'start' })

      // objDiv.scrollTop = objDiv?.scrollHeight + 200
    }
  }

  const handleNewChatPanel = () => {
    
    let tempChatId = v4();
    
    let tempChatPanel = [{
      chatMessages: [],
      chatId: tempChatId
    }, ...chatPanelData];

    setChatPanelData([...tempChatPanel]);
    setChatId(tempChatId);
    setChatMessages([]);
    setSelectedPanelIndex(0)
  }


  const handleChangePanel = (value, index) => {
    let tempChatPanel = [...chatPanelData];

    let selectedOne = tempChatPanel?.[index]

    setChatId(selectedOne?.chatId);
    setChatMessages([...selectedOne?.chatMessages]);
    setChatPanelData([...tempChatPanel]);
    setSelectedPanelIndex(index)

  }

  const gridRef = useRef()

  const [sidePanelHover, setSidePanelHover] = useState(null)
  const [loaderText, setloaderText] = useState("")

  const updateChatState = (args, tempApiLoader) => {
    
    if (tempApiLoader){
      let latChatId = null;
      setChatId((prevState) => {
        latChatId = prevState;
        
        return prevState
      })
      if(latChatId === args?.chatId){
        
        let tempChatMessages = null;

        setChatMessages((prevState) => {
          tempChatMessages = [...prevState]
          return [...prevState]
        })

        let tempChatPanel = null
        setChatPanelData((prevChatPanel) => {
          tempChatPanel = prevChatPanel;
          return prevChatPanel
        })

        tempChatMessages.push({
          "from": "ai",
          "text": args?.response
        })

        let panelIndex = null;
        setSelectedPanelIndex((prevPanelIndex) => {
          panelIndex = prevPanelIndex
          return prevPanelIndex
        })

        tempChatPanel[panelIndex].chatMessages = [...tempChatMessages]
        tempChatPanel[panelIndex].loader = false
        
        setChatPanelData([...tempChatPanel]);
        setChatMessages([...tempChatMessages]);
      } else {
        let tempChatPanel = null;
        setChatPanelData((prevState) => {
          tempChatPanel = [...prevState]
          return [...prevState]
        })

        let streamChatIndex = tempChatPanel.findIndex(f => f?.chatId === args?.chatId);
        
        tempChatPanel?.[streamChatIndex]?.chatMessages?.push({
          "from": "ai",
          "text": args?.response
        })
        tempChatPanel[streamChatIndex].loader = false

        setChatPanelData([...tempChatPanel])
        }     
    }
  }

  useEffect(() => {
    steerSocket.on("steer_token", (token) => {
        if(setloaderText)
          setloaderText(prevState => prevState+token?.token)
  
        scrollToBottom()
      })
    
    steerSocket.on("steer_stream_stopped", (args) => {
      let tempApiLoader = null;
      setApiLoader((prevState) => {
        tempApiLoader = prevState;
        return null
      });

      let tempLoaderText = null;
      setloaderText((prevState) => {
        tempLoaderText = prevState;
        return "";
      });

      updateChatState({
        response: tempLoaderText,
        chatId: tempApiLoader
      }, tempApiLoader)
    })

    steerSocket.on("steer_streamed", (args) => {
      let tempApiLoader = null;
      setApiLoader((prevState) => {
        tempApiLoader = prevState;
        return null
      });
      setloaderText("")

      updateChatState(args, tempApiLoader)
    })
  }, [])

  const handleRegenerate = () => {

    
    let tempChatMessages = [...chatMessages]
    tempChatMessages.pop();

    let userMessageIndex = tempChatMessages?.length - 1;
    let userChatText = tempChatMessages?.[userMessageIndex]?.text;

    setChatMessages([...tempChatMessages]);
    setApiLoader(chatId);

    steerSocket.emit("user_chat", {
      "message": userChatText,
      "chatId": chatId
    })
  }

  const [hoverButton, setHoverButton] = useState(null)

  return (
    <CustomGrid container spacing={3} style={{height: "90vh", maxWidth: "100%", padding: 10, overflowY: "hidden"}}>
      <Grid item spacing={2} md={2} style={{marginLeft: "10px"}}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{marginTop: "1%"}}></Grid>
          <Grid item xs={12} style={{display: "flex", alignContent: "center"}}>
            <img src={steerWheel} style={{width: "40px", height: "40px", marginLeft: isMobile ? "5%" : "1%"}} />
            <Typography variant="h5" color='secondary' style={{
              marginLeft: "10px",
              marginTop: "5px",
              fontSize: "28px"
              }}>
                <span style={{
                  background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>Steer</span>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained"
              onMouseEnter={() => setHoverButton("newChat")}
              onMouseLeave={() => setHoverButton(null)}
              style={{
                  borderRadius: "5px",
                  backgroundColor: hoverButton === "newChat" ? "#252525" : "#1c1c1c",
                  fontSize: "16px",
                  padding: 15,
                  borderRadius: "15px",
                  border: "1px solid rgba(179, 182, 183, 0.2)",
                  fontWeight: 'bold',
                  // height: "26px"
                  color: "#AAB7B8"
                }} onClick={() => handleNewChatPanel()} startIcon={<Add />} disableElevation>
              New Chat
            </Button>
            <Divider className={classes.gradientDivider1} />
          </Grid>

          <CustomGrid item xs={12} style={{height: "59vh", overflowY: "auto"}}>
            {chatPanelData && chatPanelData?.map((cpValue, cpIndex) => (
              <Grid container style={{
                marginTop: cpIndex ? "5px" : null
              }}
              onMouseEnter={() => setSidePanelHover(cpIndex)}
              onMouseLeave={() => setSidePanelHover(null)}
              onClick={() => handleChangePanel(cpValue, cpIndex)}
              >
                <Grid item xs={12}>
                  <div style={{
                  borderRadius: "15px",
                  backgroundColor: cpIndex === selectedPanelIndex ? "#303030" : (sidePanelHover === cpIndex ? "#1B2631" : null),
                  // border: 'none', // Remove the default button border
                // boxShadow: cpIndex === selectedPanelIndex ? '0 1px 0 1px #4D63DD, 0 0 0 2px #22A1F5' : '0 0px 0 0px #4D63DD, 0 0 0 1px #22A1F5',
                  display: "flex",
                  alignItems: "center",
                  padding: 15,
                  // color: "#4D63DD",
                  maxHeight: "35px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer"
                  }}
                  >
                    <ChatOutlined style={{fontSize: "16px"}}/>
                    <Typography variant="subtitle2" style={{marginLeft: 5, overflowY: "hidden", overflowX: "hidden", textOverflow: "ellipsis", maxHeight: "35px", whiteSpace: "nowrap", color: "#AAB7B8"}}>
                      {cpValue?.chatMessages?.[0]?.text ? cpValue?.chatMessages?.[0]?.text : "New Chat"}
                    </Typography>
                    {cpIndex === sidePanelHover ? 
                      <IconButton style={{marginLeft: "auto", padding: 0}} disableFocusRipple disableRipple disableTouchRipple onClick={(e) => {
                        e.stopPropagation();
                        let tempChatPanelData = [...chatPanelData];
                        tempChatPanelData.splice(cpIndex, 1);

                        setChatPanelData([...tempChatPanelData])
                      }}>
                        <DeleteOutlineOutlined style={{fontSize: "18px", padding: 0, color: "#AAB7B8"}} />
                      </IconButton> : null}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  
                </Grid>
              </Grid>
            ))}
          </CustomGrid>

          {/* <Grid item xs={12} style={{marginTop: "auto"}}>
            <Divider className={classes.gradientDivider1} />
            <Grid container>
              <Grid item xs={12}>
                <div style={{
                borderRadius: "5px",
                backgroundColor: "#D6EAF8",
                // border: 'none', // Remove the default button border
                // boxShadow: '0 1px 0 1px #4D63DD, 0 0 0 2px #22A1F5',
                display: "flex",
                alignItems: "center",
                padding: 5,
                // color: "#4D63DD"
                }}>
                  <Avatar sizes='small' variant='circle' style={{width: 20, height: 20, marginRight: 5, backgroundColor: "black"}}></Avatar> BeatO
                </div>
              </Grid>
            </Grid>
          </Grid> */}

        </Grid>          
        {/* <Divider orientation='vertical' className={classes.gradientDivider} style={{
          position: "absolute", 
          top: -15, 
          left: "17%",
          }} /> */}
      </Grid>

      <Grid item container md={9} justifyContent="center" style={{overflowY: "hidden", backgroundColor: "#5D6D7E", borderRadius: "30px", marginLeft: "40px"}}>

        <CustomGrid item xs={12} ref={ref => gridRef.current = ref} style={{maxHeight: "76vh", overflowY: "auto", overflowX: "hidden"}}>
          <Grid container spacing={1} style={{paddingTop: 20}}>

            {chatMessages && chatMessages?.length ? chatMessages?.map((val, idx) => (
            <>  <Grid item xs={12} container justifyContent='center'
                style={{
                  // backgroundColor: val?.from !== 'user' ?  "#EAEDED" : "transparent",
                  padding: 10
                }}
              >
                {val?.from !== "user" ? <Grid item xs={12} md={10}>
                  <Paper style={{padding: 20, borderRadius: "20px", background: "#1c1c1c"}} elevation={0}>
                    <Grid container>
                      <ChatPaper chatId={chatId} chatData={val} chatIndex={idx} scrollToBottom={scrollToBottom} parseIntoGraph={
                        chatMessages?.[idx-1]?.text?.includes("broadcast") || chatMessages?.[idx-1]?.text?.includes("event") ? parseIntoGraph : null
                        } chatLength={chatMessages?.length}/> 
                    </Grid>
                  </Paper>
                </Grid> : <Grid item container xs={12} md={10} style={{paddingLeft: 20, padding: 10}}>
                  <ChatPaper chatData={val} chatIndex={idx} scrollToBottom={scrollToBottom} parseIntoGraph={parseIntoGraph} chatLength={chatMessages?.length} /> 
                </Grid>}
              </Grid>
                </>
            )) : <Grid item xs={12} container justifyContent='center'>
              <Grid item container xs={12} md={10} spacing={2} style={{marginTop: "60px"}}>
                <NewChatScreen getAIMessage={getAIMessage}/>
              </Grid>
            </Grid>}

            {apiLoader === chatId ? <>  <Grid item xs={12} container justifyContent='center'
                style={{
                  // backgroundColor: "#EAEDED",
                  padding: 10
                }}
              >
                <Grid item xs={12} md={10}>
                  <Paper style={{padding: 20, borderRadius: "20px", background: "#1c1c1c"}} elevation={0}>
                    <Grid container>
                      <ChatPaper chatData={{from: "ai", loading: true}} chatIndex={-1} scrollToBottom={scrollToBottom} aiLoad={true} loaderText={loaderText} /> 
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
                </> : null}

            <Grid item xs={12} id="messageContainer"></Grid>

          </Grid>
        </CustomGrid>

        <Grid item xs={12}></Grid>

        <ChatTextField isMobile={isMobile} getAIMessage={getAIMessage} apiLoader={apiLoader} stopStreaming={stopStreaming} handleRegenerate={handleRegenerate} chatMessagesLength={chatMessages?.length}/>
      </Grid>


    </CustomGrid>
  );
};

export default SteerChat;



const ChatTextField = ({isMobile, getAIMessage, apiLoader, stopStreaming, handleRegenerate, chatMessagesLength}) => {

  const inputProps = {
    style: {
      background: "rgba(0, 0, 0, 0.7)",
      fontSize: "15px",
      // boxShadow: "0 0 10px rgba(255, 255, 255, 0.3)",
      height: "60px",
      // border: "solid black",
      // borderColor: "#3498DB",
      borderRadius: "20px",
      alignItems: "top",
      color: "#AAB7B8"
    },
  };
  
  const classes = useStyles();

  const [chatText, setChatText] = useState('')
  const anchorRef = useRef(null);
  
  
  return (
    <React.Fragment>
            <Grid item xs={12} md={4}>
              <TextField variant='outlined'
                // className={classses.gradientBorder}
                style={{marginTop: "2%", position: "absolute", bottom: "3%", width: "60%", left: "26%"}}
                inputRef={anchorRef}
                multiline
                // rows={2}
                maxRows={5}
                focused
                autoFocus
                InputProps={{ ...inputProps, 
                  disableUnderline: true, 
                  classes: {
                  notchedOutline: classes.focused
                }, 
                endAdornment: (!apiLoader ? (chatText?.length ? <IconButton size='small' style={{
                    color: "#AAB7B8"
                  }}
                  onClick={() => {
                    if(!apiLoader){
                      getAIMessage(chatText)
                      setChatText("");
                    }
                  }}
                  >
                    <Send style={{
                      fontSize: "20px"
                    }}/>
                  </IconButton> : null) : (
                    <GenericAnimation animationData={loadingAnimation} width={50} height={60}  />
                  )) }}
                placeholder='Ask Anything....'
                // helperText="Valid Urls: WebMD | WikiPedia | DuckDuckGo | Notion | Github"
                inputProps={{className: classes.placeholder}}
                onKeyDown={(event) => {
                  if (event?.key === "Enter"){
                    if(chatText?.trim() && chatText !== " " && !apiLoader){
                      getAIMessage(chatText)
                      setChatText("");
                    }
                  }
                }}

                value={chatText}
                onChange={(e) => {
                  if(e?.nativeEvent?.inputType !== "insertLineBreak")
                    setChatText(e.target?.value);
                }}
                />

              {chatMessagesLength ? 
              <Button color='primary' variant='contained' startIcon={apiLoader ? <Stop /> : <Loop />} style={{position: "absolute", right: "15%", bottom: "11%", zIndex: 999}} onClick={apiLoader ? stopStreaming : handleRegenerate}>
                {apiLoader ? "Stop generating" : "Regenerate"}
              </Button> : null}
            </Grid>
        </React.Fragment>
    )
}


const ChatPaper = ({isMobile, chatId, chatData, chatIndex, scrollToBottom, parseIntoGraph, chatLength, aiLoad, loaderText}) => {

  const captionIndex = useRef(0)

  const [aiCaption, setAICaption] = useState(chatData?.from === 'ai' ? {"text": chatData?.text} : null)

  useEffect(() => {
    if(chatData?.from === 'ai'){
      setAICaption({text: chatData?.text});
    }
  }, [chatData])

  const [graphData, setGraphData] = useState(null)  
  const [graphButtonLoader, setGraphButtonLoader] = useState(false)

  useEffect(() => {
    if(chatData?.graphData){

      let tempGraphData = []
      setGraphButtonLoader(false)

      if (chatData?.graphData?.llmOutput){
        chatData?.graphData?.Xs?.map((v, i) => {
          tempGraphData.push({
            "Xs": v,
            "Ys": Number(chatData?.graphData?.Ys?.[i]?.replace(",", ''))
          })
        });
  
        setGraphData([...tempGraphData])

      } else {
        tempGraphData = {...chatData?.graphData}
        setGraphData({...tempGraphData})
      }
    }
  }, [chatData])

  useEffect(() => {
    if(chatIndex === chatLength - 1)
    scrollToBottom()
  }, [graphData])


  const displayChart = () => {
    try {
      if (graphData?.functionType === "broadcast_data_engine"){
        return (
          <AnalyticBarChart data={graphData?.graphData} />
        )

      } else if (graphData?.functionType === "event_data_engine") {
        return (
        <AnalyticBarChart data={graphData?.graphData} argumentKey='eventName' dataKeys={["sentCount", "deliveredCount", "readCount"]}/>
        )
      } else {

      return (
        <AnalyticBarChart data={graphData?.graphData} argumentKey='Ys' dataKeys={["Xs"]}/>
      )
      }
    } catch(error){
      setGraphData(null)
      console.log(error, 'error in graph generation')
      return null
    }
  }

    return (
      <React.Fragment>
        <Grid item xs={12} style={{ display: "flex", overflowWrap: "break-word", wordBreak: "break-word", overflowX: "hidden"  }}>
          
          {!aiLoad && ((chatIndex !== (chatLength - 1)) || chatData?.from === 'user' ) ? <Avatar style={{width: "25px", height: "25px", marginRight: "10px", background: chatData?.from === "user" ? "black" : "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)"}} /> : <>
            <img src={gifLoader} style={{width: "28px", height: "28px"}}/>
          </> }
          
          <Typography variant='subtitle2' style={{whiteSpace: "break-spaces", marginTop: 2, marginLeft: 10, fontSize: "15px", fontWeight: chatData?.from !== 'ai' ? "bold" : null, color: chatData?.from === 'ai' ? "#AAB7B8" : "#1c1c1c"}}>
            {chatData?.loading ? <>
              {loaderText?.length  ? loaderText : <GenericAnimation animationData={loadingAnimation1} height={20} width={40} />}
            </> : (chatData?.from === 'ai' && chatIndex === chatLength - 1 ? aiCaption?.text : chatData?.text)}
          </Typography>
        </Grid>

        <Grid item xs={12}></Grid>

        {(!graphData || (!graphData?.length && !graphData?.functionType)) && chatData?.from === 'ai' && parseIntoGraph && chatData?.text?.length && !chatData?.waTemplate && !chatData?.broadcastTemplate ? 
          <Grid item xs={12} style={{marginTop: "20px", display: "flex", alignItems: "center"}}>
            <Button variant="contained" color='primary' disableElevation style={{ borderRadius: "20px", backgroundColor: "#EBF5FB", color: "#212F3C", border: "1px solid", fontWeight: "bold"}} endIcon={graphButtonLoader ? <CircularProgress style={{height: 15, width: 15}} /> : <BarChart />} onClick={() => {
              if (!graphButtonLoader){
                parseIntoGraph(chatData, chatIndex);
                setGraphButtonLoader(true)
              }
            }}>
              View in Graph
            </Button>
        </Grid> : null }

        {graphData && (graphData?.length || graphData?.functionType) ? <Grid item xs={12} style={{marginTop: "20px"}}>
          {displayChart()}
        </Grid> : null}

        <Grid item xs={12}></Grid>

          {chatData && chatData?.waTemplate ? <Grid item xs={12} style={{marginTop: "20px"}}>
            <WhatsappTemplateForm />
          </Grid>: null}


          {chatData && chatData?.broadcastTemplate ? <Grid item xs={12} style={{marginTop: "10px", padding: 10}}>
            <AddNewBroadcast1 onlyAdd={true} />
          </Grid>: null}

      </React.Fragment>
    )
}


const NewChatScreen = ({isMobile, getAIMessage}) => {

  const cardData = [{
    title: "Understand",
    linear: "linear-gradient(128.8deg, #E67E22 28.61%, #E74C3C 99.07%)",
    options: [
      "What events were triggered in last month?",
      "What is the last broadcast?",
      "Give me last 3 broadcast numbers."
    ]
  }, {
    title: "Create & Explore",
    linear: "linear-gradient(128.8deg, #AF7AC5 28.61%, #8E44AD 99.07%)",
    options: [
      "I want to create a whatsapp template for broadcast.",
      "Schedule a broadcast for me.",
      "What is the last broadcast content and how can I improve it more?"
    ]
  }]


    return (
      <React.Fragment>
        
        <Grid item xs={12}>
          <Typography variant="h2" style={{fontWeight: "bold", fontSize: "36px", color: "#212F3C"}}>
            Welcome To Steer
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" style={{color: "#212F3C"}}>
            Tell me what’s on your mind or pick a suggestion. 
          </Typography>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12} container spacing={1}>
          
          {cardData?.map(val => (
            <Grid item xs={12} md={6}>
              <Paper style={{padding: 20, borderRadius: "20px", background: "#1c1c1c"}}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant='h5' style={{fontWeight: "bold", fontSize: "26px"}}>
                      <span style={{
                        background: val?.linear,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                      }}>
                        {val?.title}
                      </span>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}></Grid>

                    {val?.options?.map(opt => (
                      <Grid item xs={12}>
                        <Button variant="contained" style={{backgroundColor: "#303030", marginTop: 5, borderRadius: "50px", textAlign: "left", color: "#AAB7B8"}} disableElevation onClick={() => getAIMessage(opt)}>
                          {opt}
                        </Button>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>
          ))}

        </Grid>
      </React.Fragment>
    )
}
