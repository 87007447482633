import React, { useEffect, useState } from "react"
// nodejs library that concatenates classes
import ReactGA from "react-ga4"
import classnames from "classnames"
// reactstrap components
import {
  Button,
  Container,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap"

//assets
import LoginArt from "../../../assets/img/login/LoginArt.svg"
import paperplaneLogo from "../../../assets/logos/Group 37270.png"
import smileLogo from "../../../assets/logos/Group 36933.png"
import logo from "../../../assets/logos/apolloLogo.png"
import Logo from "../../../assets/logos/Logo Light.png"
import Alert from "reactstrap/lib/Alert"
import animationData from './../../../assets/animationData/pcs_login_page.json';
import Lottie from 'react-lottie';


//Redux
import { useDispatch, useSelector } from "react-redux"
//Redux actions
import { endLoading, loginUser } from "../../../redux/actions/auth.actions"
import LoadingAnimation from "./LoadingAnimation"
import { Link } from "react-router-dom"
import { IconButton, Typography } from "@material-ui/core"

const Login = ({ ...rest }) => {
  const auth = useSelector((state) => state.auth)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    renderer: 'svg',
  };

  const isLoading = useSelector((state) => state.auth.isLoading)
  const dispatch = useDispatch()
  const defaultFullData = {
    username: "",
    password: "",
  }
  const [showPassword, setShowPassword] = useState(false)
  const handleShowpassword = (showPassword) => {
    const element = document.getElementById("passwordField")
    console.log("elemt", element)
    element.setAttribute("type", showPassword ? "text" : "password")
  }
  const [state, setState] = useState({})
  const [alertState, setAlertState] = useState({
    show: false,
    type: "success",
    msg: "Login successful!",
  })

  const [fullData, setFullData] = useState(defaultFullData)
  useEffect(() => {
    return () => {
      dispatch(endLoading())
    }
  }, [])
  useEffect(() => {
    console.log("uthis")
    if (auth.isAuthenticated) {
      dispatch(endLoading())
    } else {
      console.log("User not authenticated")
    }
  }, [auth])
  const handleLogin = async (fullData) => {
    ReactGA.event({
      category: "Authentication",
      action: "Login",
    })

    dispatch(loginUser(fullData))
  }
  const sendRegisterEvent = () => {
    ReactGA.event({
      category: "Authentication",
      action: "Register",
    })
  }
  useEffect(() => {
    handleShowpassword(showPassword)
  }, [showPassword])
  return (
    <>
      <Alert
        style={{ innerWidth: "100px" }}
        isOpen={alertState.show}
        color={alertState.type}
        toggle={() => setAlertState({ ...alertState, show: false })}
        fade={true}
      >
        {alertState.msg}
      </Alert>
      
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col
            md="5"
            sm="1"
            style={{
              height: "100vh",
              backgroundColor: "#1c1c1c",
              // borderRadius: "3rem",
            }}
          >
            <Container>
              {/* <div
                className="m-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                style={{ width: "150px", objectFit: "contain" }}
                src={paperplaneLogo}
                />
              </div> */}

            </Container>
            <center style={{marginTop: "20%"}}>
              {/* <img
                src={LoginArt}
                alt="art"
                style={{
                  alignItems: "center",
                  marginLeft: "2rem",
                  overflow: "visible",
                  width: "85%",
                  paddingTop: "4vh",
                }}
              />
               */}
               <Lottie
                  options={defaultOptions}
                  height={"85%"}
                  width={"85%"}
                  isStopped={false}
                  isPaused={false}
                />
            </center>
          </Col>
          <Col md="1" style={{ backgroundColor: "black" }}>
          </Col>
          <Col md="6" style={{ backgroundColor: "black" }}>
            <img
            src={smileLogo}
            alt="logo"
            style={{ position: "absolute", right: "10%", width: "25%", top: "5%" }}
          ></img>
            <div style={{ paddingTop: "22%" }}>
              <div className="pl-5 mt-5">
                <h1 className="display-2 mb-0" style={{color: "#AAB7B8"}}>Sign In</h1>
                <p style={{color: "#AAB7B8"}}>Login with your Phone number or Email </p>

                <Row>
                  <Col md="9">
                    <Form role="form">
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: state.focusedEmail,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <Input
                            id="emailInput"
                            className="form-control-flush p-3"
                            placeholder="Phone/Email"
                            type="email"
                            value={fullData.username}
                            invalid={true}
                            onChange={(e) =>
                              setFullData({
                                ...fullData,
                                username: e.target.value,
                              })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleLogin(fullData)
                              }
                            }}
                            style={{borderColor: "white"}}
                            onFocus={() => setState({ focusedEmail: true })}
                            onBlur={() => setState({ focusedEmail: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: state.focusedPassword,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative ">
                          <Input
                            className="form-control-flush p-3 passwordField"
                            placeholder="Password"
                            id="passwordField"
                            type="password"
                            value={fullData.password}
                            onChange={(e) =>
                              setFullData({
                                ...fullData,
                                password: e.target.value,
                              })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleLogin(fullData)
                              }
                            }}
                            onFocus={() => setState({ focusedPassword: true })}
                            onBlur={() => setState({ focusedPassword: false })}
                          />

                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            {showPassword ? (
                              <i class="fas fa-eye-slash"></i>
                            ) : (
                              <i class="fas fa-eye"></i>
                            )}{" "}
                          </IconButton>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        {isLoading && <LoadingAnimation></LoadingAnimation>}
                        {!isLoading && (
                          <Button
                            className="my-4 p-3"
                            color="primary"
                            type="button"
                            block
                            onClick={() => handleLogin(fullData)}
                          >
                            Login
                          </Button>
                        )}
                      </div>
                    </Form>

                    <div>
                      <Link
                        to="/auth/forgotPassword"
                        onClick={() => {
                          ReactGA.event({
                            category: "Authentication",
                            action: "Forgot Password",
                          })
                        }}
                      >
                        {" "}
                        Forgot Password?
                      </Link>
                    </div>
                    <div>
                      <span  style={{color: "#AAB7B8"}}>
                        Don’t have an account? &nbsp;
                      </span>
                      <a
                        href="https://website.paperplane.clinic"
                        alt="register"
                        target="_blank"
                        onClick={sendRegisterEvent}
                      >
                        Sign up
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <div
        className="pl-5 my-3"
        style={{ position: "fixed", left: "0", bottom: "0" }}
      >
        <Row>
          <a
            style={{ textDecoration: "none" }}
            href="https://paperplane-communications.notion.site/About-Us-29434990024e484c943b9e02da0d5a21"
          >
            <u>About us</u>
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            className="link-primary"
            href="https://paperplane-communications.notion.site/Privacy-Policy-9af553d230814ef5aac92c09a3328864"
          >
            <u>Privacy Policy</u>
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            className="link-primary"
            href="https://paperplane-communications.notion.site/Return-and-Refund-Policy-389bcefbbcb44b61b17fea6473d9bde8"
          >
            <u>Refund/Cancelation Policy</u>
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            className="link-primary"
            href="https://paperplane-communications.notion.site/Pricing-543bcb53632547e39cdc3f4c34f06dae"
          >
            <u>Pricing</u>
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            className="link-primary"
            href="https://paperplane-communications.notion.site/Terms-and-Conditions-c855993701794fb9aba1ecf0dc681c8a"
          >
            <u>Terms and Conditions</u>
          </a>
        </Row>
      </div> */}
    </>
  )
}

export default Login
